import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { FilesService } from '../files.service';
import { take } from 'rxjs/operators';

declare var $: any;

@Component({
    selector: 'app-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit, OnDestroy {

    dropzoneActive: boolean = false;

    // State for dropzone CSS toggling
    isHovering: boolean;

    entityID: string;
    uploadingFile: boolean = false;

    // Save Ref URL in Firestore
    @Input() saveRef: any;
    @Input() filesRef: string;
    @Output() refValues = new EventEmitter();
    @Output() uploaded = new EventEmitter();
    @Output() uploading = new EventEmitter();
    @Output() totalUploadSize = new EventEmitter();
    @Output() imagesArray = new EventEmitter();

    largeImageArray: any[] = [];

    // CROPPER
    imageChangedEvent: any = '';
    fileType: string;
    fileName: string;
    fileSize: number;
    croppedImage: any = '';
    showCropper = false;
    imageFile: any;
    uploadImages: any[] = [];
    uploadingImage: boolean = false;
    imgSizeMessage: any = '';

    constructor(
        public filesService: FilesService
    ) {

    }

    ngOnInit() {
      this.filesService.getUploadStatus().subscribe(value => {
          this.uploadingFile = value.uploading;
          this.uploading.emit(value.uploading);
      });
    }

    toggleHover(event: boolean) {
        this.isHovering = event;
    }

    handleDrop(fileList) {
        let files;
        if (fileList.target) {
          files = fileList.target.files;
        } else {
          files = fileList
        }
        if (this.saveRef.uploadType === 'multiple') {
          this.handleMultipleFiles(files);
        }
        if (this.saveRef.uploadType === 'gallery') {
          this.handleMultipleImages(files);
        }
    }

    // MULTIPLE FILES
    handleMultipleFiles(files) {
      let allowUpload = false;
      let totalFileSize = 0;
      this.totalUploadSize.emit(totalFileSize);
      if ((this.saveRef.filesLength + files.length) > this.saveRef.fileLimit) {
          this.filesService.getFiles(this.filesRef).pipe(take(1)).subscribe(existingFiles => {
              for (let i of existingFiles) {
                  totalFileSize += +i['size'];
              }
              for (let i of files) {
                  totalFileSize += +i['size'];
              }
              this.totalUploadSize.emit(totalFileSize);
              if (totalFileSize < 2000000) {
                  allowUpload = true;
              } else {
                  allowUpload = false;
                  console.log('File can not be more than 2mb');
              }

              if (files && allowUpload) {
                  const uploadFiles = this.filesService.handleDrop(files, this.saveRef);
                  return Promise.resolve(uploadFiles).then(() => {
                      this.filesService.getUploadStatus().subscribe(values => {
                          this.refValues.emit(values);
                          if (!values.uploading) {
                              this.uploaded.emit(true);
                          }
                      });
                  });
              }
          });
      } else {
          allowUpload = true;
          if (files && allowUpload) {
              const uploadFiles = this.filesService.handleDrop(files, this.saveRef);
              return Promise.resolve(uploadFiles).then(() => {
                  this.filesService.getUploadStatus().subscribe(values => {
                      this.refValues.emit(values);
                      if (!values.uploading) {
                          this.uploaded.emit(true);
                      }
                  });
              });
          }
      }
    }

    // MULTIPLE IMAGES (eg: GALLERY)
    handleMultipleImages(files) {
      if (this.saveRef.uploadType === 'gallery') {
        if ((this.saveRef.fileLengthRef + files.length) > this.saveRef.maxFileLimitRef) {
            console.log(`Maximum files exceeded! Only ${this.saveRef.maxFileLimitRef} images allowed`);
            return false;
        } else {
          if (this.saveRef.action === 'save') {
            if (files) {
              this.largeImageArray = [];
              const newFiles = [].slice.call(files).filter(newFile => {
                if (newFile.size <= 2000000) return newFile;
                if (newFile.size > 2000000) {
                  const fileDetails = {
                    name: newFile.name,
                    size: (newFile.size / (1024*1024)).toFixed(0)
                  }
                  this.largeImageArray.push(fileDetails);
                }
              });
              const locationPhotosArray: any = [];
              let pathInfo = [];
              Array.from(newFiles).forEach((image, index, array) => {
                let count = this.saveRef.fileLengthRef + index;
                let imgInfo = {
                  filePath: this.saveRef.filePath,
                  dbPath: this.saveRef.dbPath,
                  order: count++,
                };
                locationPhotosArray.push(image);
                pathInfo.push(imgInfo);
                if (locationPhotosArray.length === array.length && pathInfo.length == array.length) {
                  const uploadFiles = this.filesService.handleGalleryDrop(locationPhotosArray, pathInfo, null);
                  return Promise.resolve(uploadFiles).then(() => {
                    this.filesService.getUploadStatus().subscribe(values => {
                        this.imagesArray.emit(values);
                    });
                  });
                } else {
                  return Promise.resolve('Failed to add images ');
                }
              });
            }
          }
          if (this.saveRef.action === 'store') {
            if (files) {
              const images = [];
              this.largeImageArray = [];
              const newFiles = [].slice.call(files).filter(newFile => {
                if (newFile.size <= 2000000) return newFile;
                if (newFile.size > 2000000) {
                  const fileDetails = {
                    name: newFile.name,
                    size: (newFile.size / (1024*1024)).toFixed(0)
                  }
                  this.largeImageArray.push(fileDetails);
                }
              });
              for (let file of newFiles) {
                let reader = new FileReader();
                reader.onload = (e: any) => {
                  let imgInfo = {
                    url: e.target.result,
                    imgName: file.name,
                    imgSize: file.size,
                    imgType: file.type,
                    file: file
                  };
                  images.push(imgInfo);
                  if (images.length === newFiles.length) {
                    this.imagesArray.emit(images);
                    this.uploadingFile = false;
                  }
                };
                reader.readAsDataURL(file);
              }
            } else {
              this.uploadingFile = false;
            }
          }
        }
      } else {
          if (files) {
              const uploadFiles = this.filesService.handleGalleryDrop(files, this.saveRef);
              return Promise.resolve(uploadFiles).then(() => {
                  this.filesService.getUploadStatus().subscribe(values => {
                      this.refValues.emit(values);
                  });
              });
          }
      }
    }

    // SINGLE IMAGE CROPPER
    openCropper() {
      $("#cropperModal").modal("show");
    }

    imageChangeEvent(event: any): void {
      this.uploadingImage = true;
      this.showCropper = false;
      if (event.target.files.length > 0) {
        this.fileType = event.target.files[0].type;
        this.fileName = event.target.files[0].name;
        this.fileSize = event.target.files[0].size;
        if (this.fileSize <= 2000000) {
          this.imageChangedEvent = event;
          this.uploadingImage = false;
          this.imgSizeMessage = '';
        } else {
          this.imageChangedEvent = '';
          this.uploadingImage = false;
          this.croppedImage = '';
          this.imgSizeMessage = 'Image can not be larger than 2MB';
        }
      } else {
        this.uploadingImage = false;
      }
    }

    imageCropped(event: ImageCroppedEvent) {
      console.log(event)
      if (this.fileSize <= 2000000) {
        this.croppedImage = event.base64;
        this.imageFile = this.filesService.dataUrlToFileList([this.croppedImage], [this.fileName], [this.fileType]);
      } else {
        this.croppedImage = '';
        this.imageFile = [];
      }
    }

    imageLoaded() {
      if (this.fileSize <= 2000000) {
        this.showCropper = true;
        this.uploadingImage = false;
      } else {
        this.showCropper = false;
        this.uploadingImage = false;
      }
        // show cropper
    }

    cropperReady() {
        // cropper ready
    }

    loadImageFailed() {
        // show message
    }

  uploadImage(action) {
    if (action === 'save') {
      const uploadFiles = this.filesService.handleDrop(this.imageFile, this.saveRef);
      return Promise.resolve(uploadFiles).then(() => {
          this.filesService.getUploadStatus().subscribe(values => {
              this.refValues.emit(values);
              if (!values.uploading) {
                  this.uploaded.emit(true);
                  this.croppedImage = '';
                  this.imageChangedEvent = '';
                  this.showCropper = false;
              }
          });
      });
    }
    if (action === 'store') {
      const dataToSend = {
        imgPreview: this.croppedImage,
        file: this.imageFile
      };
      this.refValues.emit(dataToSend);
      $("#cropperModal").modal("hide");
    }
  }

  refValue(file) { // You can give any function name
      const fileDetails = file;
      this.refValues.emit(fileDetails);
  }

  onModalHide() {
    $('#cropperModal').on("hidden.bs.modal", () => {
      if (this.showCropper) this.showCropper = false;
      if (this.croppedImage) this.croppedImage = '';
      this.imageChangedEvent = '';
      this.uploadingImage = false;
      this.imgSizeMessage = '';
    });
  }

  ngOnDestroy() {
  }

}
