import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { StorageService } from 'src/app/_shared/_services/storage.service';
import { Subject, Subscription, Observable } from 'rxjs';
import { User } from '../../../../../theme/pages/wealth-spaces/users/_models/user.model';
import { EventsService } from 'src/app/theme/pages/wealth-spaces/events/_services/events.service';
import { BookingsService } from 'src/app/_shared/_components/wealthspaces/ws-bookings/_services/bookings.service';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'ws-events-list',
  templateUrl: './ws-events-list.component.html'
})
export class WSEventsListComponent implements OnInit, OnDestroy {

  @Input() events: any[];
  @Input() todaysDate: any;

  allEvents = [];

  availableSpaces: number = 0;
  eventClosed: boolean = false;

  // FILTERS
  provincesToFilter = [];

  //USER
  userSubscription: Subscription;
  user: User;
  userBookingsSubscription: Subscription;
  userBookings: any[] = [];

  constructor(
    private storageService: StorageService,
    private eventsService: EventsService,
    private bookingsService: BookingsService
  ) {
  }

  ngOnInit() {
    this.allEvents = this.events;
    this.allEvents.forEach(event => {
      if (this.provincesToFilter.indexOf(event.address.region) === -1) {
        this.provincesToFilter.push(event.address.region);
      }
    });
    this.userSubscription = this.storageService.userDetails.subscribe(user => {
      if (Object.keys(user).length > 0) {
        this.user = user;
        this.userBookingsSubscription = this.bookingsService.getUserRsvpBookings(this.user.uid).subscribe(userBookings => {
          if (userBookings) {
            this.userBookings = userBookings;
          }
        });
      }
    });
  }

  filterEvents(event, type) {
    const time = $('#event-time').children('option:selected').val();
    const province = $('#province-select').children('option:selected').val();
    let dates;
    let region;
    if (time || province) {
      let temp = this.allEvents.filter(event => {
        const eventStart = new Date(event.startDate.toDate());
        const todaysDate = new Date(this.todaysDate);
        dates = (time === 'past') ? eventStart < todaysDate : eventStart >= todaysDate;
        region = event.address.region === province;
        if (time && !province) return dates;
        if (!time && province) return region;
        if (time && province) return dates && region;
      });
      this.events = temp;
    } else {
      this.events = this.allEvents;
    }

  }

  resetFilters() {
    $("#event-time").val("");
    $("#province-select").val("");
    this.events = this.allEvents;
  }

  getSpaces(available, booked) {
    return available - booked;
  }

  checkDates(date) {
    return new Date(date.toDate()) > new Date(this.todaysDate);
  }

  checkIfUserAttending(eventId) {
    if (Object.keys(this.userBookings).length > 0) {
      if (this.userBookings.some(booking => booking.uid === eventId)) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  // TIMES
  toTime(time: any): string {
    if (!time) {
      return null;
    }
    let meridian;
    if (time.hour > 11) {
      meridian = 'PM'
    } else {
      meridian = 'AM'
    }
    return `${this.pad(time.hour)}:${this.pad(time.minute)} ${meridian}`;
  }

  private pad(i: number): string {
    return i < 10 ? `0${i}` : `${i}`;
  }

  openRSVP(event) {
    const modalData = {
      modalId: 'eventsModal',
      title: `RSVP to ${event.title}`,
      type: 'rsvpEvent',
      data: {
        event: event,
        user: this.user
      }
    }
    this.storageService.openModal(modalData);
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
  }

}
