import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { EventsService } from '../../../../../theme/pages/wealth-spaces/events/_services/events.service';
import { User } from '../../../../../theme/pages/wealth-spaces/users/_models/user.model';
import { ToastrService } from 'ngx-toastr';
import { UsersService } from 'src/app/theme/pages/wealth-spaces/users/_services/users.service';
import { Subscription } from 'rxjs';
import { config } from '../../../../_configs/config'
import { AuthenticationService } from 'src/app/auth/_services';

@Component({
  selector: 'ws-users-view',
  templateUrl: './ws-users-view.html'
})
export class WSUsersViewComponent implements OnInit, OnDestroy {

  @Input() extraData: any;

  @Output() closeModal = new EventEmitter();

  isLoading: boolean = false;

  userSubscription: Subscription
  user: User;

  isSubmitting: boolean = false;

  viewType: string;

  locationId: string;
  ratedUserIndex: number;

  constructor(
    private auth: AuthenticationService,
    private usersService: UsersService,
    private toastr: ToastrService
  ) {
  }

  ngOnInit() {
    this.isLoading = true;

    this.viewType = this.extraData.data.type;
    this.ratedUserIndex = this.extraData.data.userIndex;
    if (this.extraData.data.locationId) {
      this.locationId = this.extraData.data.locationId;
    }
    this.userSubscription = this.usersService.fetchUserDetails(`entities/${config.entityId}/users/${this.extraData.data.userId}`).subscribe(userData => {
      this.user = userData;
      this.isLoading = false;
    });
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
  }

}
