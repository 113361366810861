import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import { take } from 'rxjs/operators';
import * as _ from "lodash";
import * as firebase from 'firebase';
import { NgxPermissionsService } from 'ngx-permissions';
import { Entity } from '../_models/entities.model';
import { config } from '../_configs/config';
import { BehaviorSubject } from 'rxjs';
import { AuthenticationService } from '../../auth/_services';

@Injectable()
export class EntitiesService {

  entityId: string;

  // ENTITY DETAILS
  public adminEntityDetails = new BehaviorSubject<Entity>({});
  public currentAdminEntity = this.adminEntityDetails.asObservable();

  entitiesCollection: AngularFirestoreCollection<Entity>;

  loggedInUser: string;
  permissions: any;
  permissionsList: any;
  userPermissions: any;
  authSubscription: Subscription;


  constructor(
    public afStore: AngularFirestore,
    private auth: AuthenticationService,
    private permissionsService: NgxPermissionsService
  ) {
    this.entityId = config.entityId;
    this.authSubscription = this.auth.user.subscribe(userDetails => {
      if (userDetails) {
        this.loggedInUser = userDetails.uid;
        // FETCH ENTITIES ASSOCIATED TO USER
        this.entitiesCollection = this.afStore.collection(`users/${this.loggedInUser}/entities`, ref => ref.orderBy('name', 'asc'));
      }
    });
  }


  fetchEntityDetails() {
    const entityDoc = this.afStore.doc(`entities/${this.entityId}`);
    return entityDoc.valueChanges();
  }

  getUserForEntity(userID: string) {
    return this.afStore.doc(`entities/${this.entityId}/users/${userID}`).valueChanges();
  }

  // PERMISSIONS
  getUserPermissionsForEntity(userId: string) {
      return this.afStore.doc(`/users/${userId}/entities/${this.entityId}`).valueChanges();
  }

  getUserPermissionsForEntitySubscription(userId: string) {
    return this.afStore.doc(`/users/${userId}/entities/${this.entityId}`).valueChanges();
  }

  updateMeetingDates(dates) {
    return this.afStore.doc(`entities/${this.entityId}`).update({
      previousAgmDate: dates.lastAgm,
      agmDate: dates.nextAgm,
      previousExecutiveMeetingDate: dates.lastExec,
      executiveMeetingDate: dates.nextExec
    });
  }
}
