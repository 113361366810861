import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { WSEventsListComponent } from './ws-events-list/ws-events-list.component';
import { WSEventsRsvpComponent } from './ws-events-rsvp/ws-events-rsvp.component';
import { NgPipesModule } from 'ngx-pipes';
import { WSEventsInviteComponent } from './ws-events-invite/ws-events-invite.component';

@NgModule({
  declarations: [
    WSEventsListComponent,
    WSEventsRsvpComponent,
    WSEventsInviteComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgPipesModule
  ],
  exports: [
    WSEventsListComponent,
    WSEventsRsvpComponent,
    WSEventsInviteComponent
  ],
  providers: []
})
export class WsEventsModule {
}
