import { Component, OnInit, ViewChild, ElementRef, NgZone, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MapsAPILoader, MouseEvent, ZoomControlOptions, ControlPosition, ZoomControlStyle } from '@agm/core';
import { Subject, Subscription, Observable } from 'rxjs';
import { mapStyles } from '../_models/styles.model';
import { StorageService } from 'src/app/_shared/_services/storage.service';

declare var google;

@Component({
  selector: 'app-maps-locations',
  templateUrl: './maps-locations.component.html',
  styleUrls: ['../maps.scss']
})
export class MapsLocationsComponent implements OnInit, OnDestroy {

    @Input() mapSettings: any;

    locationsSubscription: Subscription;
    locations: any[];

    // google maps zoom level
    zoom: number = 6;

    // initial center position for the map
    // lat: number = -26.195246;
    // lng: number = 28.034088;
    lat: number = -28.304381;
    lng: number = 24.679621;

    zoomControlOptions: ZoomControlOptions = {
      position: ControlPosition.RIGHT_TOP,
      style: ZoomControlStyle.SMALL
    };

    mapHeight: string = '80vh';

    markers = [];

    mapStyles = mapStyles;

    previous;

    constructor(
        private mapsAPILoader: MapsAPILoader,
        private ngZone: NgZone,
        private storageService: StorageService
    ) { }


    ngOnInit() {

      this.locationsSubscription = this.storageService.mapLocations.subscribe(locationsData => {

        this.markers = [];

        this.locations = locationsData;

        for(let location in this.locations) {
          if (this.locations[location].product) {
            let logo;
            if (this.locations[location].uploadedPhoto) {
              logo = this.locations[location].uploadedPhotoThumbnail;
            } else {
              logo = '/assets/images/locations/location_profile.jpg';
            }

            const date = new Date();
            const currentDay = date.getUTCDay();
            const currentHour = date.getHours();
            const currentMinute = date.getMinutes();
            let daySelect;
            switch (currentDay) {
              case 0: daySelect = this.locations[location].businessHours.sunday;
                      break;
              case 1: daySelect = this.locations[location].businessHours.monday;
                      break;
              case 2: daySelect = this.locations[location].businessHours.tuesday;
                      break;
              case 3: daySelect = this.locations[location].businessHours.wednesday;
                      break;
              case 4: daySelect = this.locations[location].businessHours.thursday;
                      break;
              case 5: daySelect = this.locations[location].businessHours.friday;
                      break;
              case 6: daySelect = this.locations[location].businessHours.saturday;
                      break;
            }
            if (daySelect.open.hour && daySelect.close.hour) {
              if (currentHour >= daySelect.open.hour && currentHour < daySelect.close.hour) {
                this.locations[location].isOpen = true;
              } else {
                if (currentHour === daySelect.open.hour && currentMinute === daySelect.open.minute &&
                currentHour < daySelect.close.hour && currentMinute < daySelect.close.minute) {
                  this.locations[location].isOpen = true;
                } else {
                  this.locations[location].isOpen = false;
                }
              }
            }

            this.markers.push({
                lat: this.locations[location].address.latitude,
                lng: this.locations[location].address.longitude,
                icon: '/assets/images/locations/ws-location.png',
                name: this.locations[location].name,
                address: this.locations[location].address.formatted_address,
                logo: logo,
                url: `/location/${this.locations[location].slug}`,
                isOpen: this.locations[location].isOpen
            });
          }
        }
      });
      this.storageService.locationFilter.subscribe(filter => {
        if (Object.keys(filter).length > 0) {
            this.geocode(filter.address).then(place => {
              this.lat = place.geometry.location.lat();
              this.lng = place.geometry.location.lng();
              this.zoom = filter.zoom;
              if (filter.mapHeight) {
                this.mapHeight = filter.mapHeight;
              }
            })
            .catch(err => {
              console.log(err);
            });
        }
      });

      if (this.mapSettings) {
        this.mapHeight = this.mapSettings.mapHeight;
      }
    }

    geocode(address: string): Promise<any> {
      const geocoder = new google.maps.Geocoder();
      return new Promise((resolve, reject) => {
        geocoder.geocode(
          {
            address: address,
            componentRestrictions: {
              country: 'za'
            }
          },

          (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
              resolve(results[0]);
            } else {
              reject(new Error(status));
            }
          }
        );
      });
    }

    clickedMarker(infowindow) {
      if (this.previous) {
          this.previous.close();
      }
      this.previous = infowindow;
   }

   ngOnDestroy() {
     this.locationsSubscription.unsubscribe();
   }


}
