import { Component, OnInit } from '@angular/core';
import { AsideNavService } from './layouts/_services/authorised-side-nav.service';
import { User } from './pages/wealth-spaces/users/_models/user.model';
import { Subscription } from 'rxjs';
import { StorageService } from '../_shared/_services/storage.service';
import { AuthenticationService } from '../auth/_services';
import { Router } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-theme',
  templateUrl: './theme.component.html'
})
export class ThemeComponent implements OnInit {

  loadingData: boolean = false;
  innerWidth: any;

  // USER
  user: User;
  userSubscription: Subscription;
  userFirstname: string;

  constructor(
    public auth: AuthenticationService,
    public sideNavService: AsideNavService,
    private storageService: StorageService,
    private router: Router
    ) {}

  ngOnInit() {
    this.loadingData = true;
    this.userSubscription = this.auth.user.subscribe(userDetails => {
      if (userDetails) {
        this.user = userDetails;
        if (!this.user.verified) {
          this.router.navigate(['/profile'])
        };
        this.userFirstname = this.user.firstname.split(' ')[0];
        this.storageService.userDetails.next(userDetails);
        this.loadingData = false;
      } else {
        this.loadingData = false;
      }
    });
  }
}
