import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl, ValidatorFn, FormArray, NgForm, FormControl } from '@angular/forms';
import { ProductsService } from 'src/app/_shared/_services/products.service';
import { StorageService } from 'src/app/_shared/_services/storage.service';
import { SubscriptionsService } from 'src/app/_shared/_services/subscriptions.service';
import { Subject, Subscription } from 'rxjs';
import { productRenewal } from 'src/app/_shared/_models/product-renewal';

@Component({
  selector: 'ws-subscription-view',
  templateUrl: './ws-subscription-view.component.html',
  styleUrls: ['../ws-products.scss']
})
export class WSSubscriptionViewComponent implements OnInit, OnDestroy {

  @Input() extraData: any;

  @Output() closeModal = new EventEmitter();
  @Output() subDetails = new EventEmitter();

  subscriptionPlan: Subscription;
  isLoading: boolean = false;

  modalSubscription: Subscription;
  subscription: any;
  renewDays = productRenewal;
  frequency: any;

  constructor(
    private fb: FormBuilder,
    private productsService: ProductsService,
    private storageService: StorageService,
    private subscriptionsService: SubscriptionsService
  ) {
  }

  ngOnInit() {
    this.subscriptionPlan = this.storageService.subscription.subscribe(subscription => {
      this.subscription = subscription;
      this.subDetails.emit(this.subscription);
      // SET FREQUENCY
      const frequency = this.renewDays.filter((e) => { return e.value === this.subscription.frequency; });
      this.frequency = frequency[0];
    });
  }

  ngOnDestroy() {
    this.subscriptionPlan.unsubscribe();
  }

}
