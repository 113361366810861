import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AuthRoutingModule } from './auth-routing.routing';
import { AuthLoginComponent } from './components/auth-login/auth-login.component';
import { RouterModule } from '@angular/router';
import { AuthGuard } from './_guards/auth.guard';
import { AuthenticationService } from './_services';
import { AuthRegisterComponent } from './components/auth-register/auth-register.component';
import { AuthSocialComponent } from './components/auth-social/auth-social.component';
import { AuthPasswordResetComponent } from './components/auth-password-reset/auth-password-reset.component';
import { AuthVerificationComponent } from './components/auth-verification/auth-verification.component';

@NgModule({
    declarations: [
        AuthLoginComponent,
        AuthPasswordResetComponent,
        AuthRegisterComponent,
        AuthSocialComponent,
        AuthVerificationComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        AuthRoutingModule
    ],
    providers: [
      AuthGuard,
      AuthenticationService
    ],
    exports: [
      AuthLoginComponent,
      AuthPasswordResetComponent,
      AuthRegisterComponent,
      AuthSocialComponent,
      AuthVerificationComponent
    ],
})

export class AuthModule {
}
