import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { environment } from '../../../../environments/environment';
import { FormsModule } from '@angular/forms';
import { CalendarHeaderComponent } from './_utils/calendar-header.component';
import { CalendarComponent } from './calendar/calendar.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CustomEventTitleFormatter } from './_services/custom-event-title.service';

@NgModule({
    imports: [
      CommonModule,
      AngularFireModule.initializeApp(environment.firebase),
      AngularFirestoreModule,
      AngularFireStorageModule,
      FormsModule,
      CalendarModule.forRoot({
        provide: DateAdapter,
        useFactory: adapterFactory
      }),
    ],
    declarations: [
      CalendarHeaderComponent,
      CalendarComponent
    ],
    providers: [
      CustomEventTitleFormatter
    ],
    exports: [
      CalendarModule,
      CalendarHeaderComponent,
      CalendarComponent
    ]
})
export class CalendarsModule { }
