import { Injectable } from '@angular/core';
import {
    AngularFirestore,
    AngularFirestoreCollection,
    AngularFirestoreDocument
} from '@angular/fire/firestore';
import { Observable, Subject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Audit } from '../_models/audit.model';
import { AuthenticationService } from '../../auth/_services/authentication.service';
import { User } from '../../theme/pages/wealth-spaces/users/_models/user.model';
import { QuerySnapshot } from '@firebase/firestore-types';
import { map } from 'rxjs/operators';
import { config } from '../_configs/config';

  @Injectable()
  export class RatingService {

    entityId: string;

    authSubscription: Subscription;
    loggedInUser: any;

    constructor(
      private afs: AngularFirestore,
      private auth: AuthenticationService
      ) {
      this.entityId = config.entityId;
      this.authSubscription = this.auth.user.subscribe(userDetails => {
        if (userDetails) {
          this.loggedInUser = userDetails;
        }
      });
    }

    // Rating reviews that belong to a user
    getUserRatings(userId) {
      const ratingsRef = this.afs.collection('stars', ref => ref.where('userId', '==', userId) );
      return ratingsRef.valueChanges();
    }

    // Get all ratings that belong to a location
    getRatingStars(ref) {
      const starsRef = this.afs.collection(ref);
      return starsRef.valueChanges();
    }

    addRating(rating, ref, type, locationId) {
      let ratingData = {
        active: true,
        createdBy: this.loggedInUser.uid,
        ratedBy: this.loggedInUser.uid,
        ratedByType: 'user',
        ratingType: type,
        created: new Date(),
        rating: rating,
        uid: this.loggedInUser.uid
      }

      if (locationId) {
        ratingData.ratedBy = locationId;
        ratingData.ratedByType = 'location';
      }

      const ratingId = (!locationId) ? this.loggedInUser.uid : locationId;

      const starDoc = this.afs.collection(ref).doc(ratingId);
      return starDoc.set(ratingData, { merge: true });
    }

    setLocationAvgRating(locationId, avgRating) {
      return this.afs.doc(`entities/${config.entityId}/locations/${locationId}`).set({avgRating}, {merge: true});
    }

  }
