import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { WSMeetingRoomsListComponent } from './ws-meeting-rooms-list/ws-meeting-rooms-list.component';
import { NouisliderModule } from 'ng2-nouislider';

@NgModule({
  declarations: [
    WSMeetingRoomsListComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NouisliderModule
  ],
  exports: [
    WSMeetingRoomsListComponent,
    NouisliderModule
  ],
  providers: []
})
export class WsMeetingRoomsModule {
}
