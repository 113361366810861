import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as _ from "lodash";
import { FilesService } from '../files.service';
declare var toastr: any;

@Component({
    selector: 'app-gallery-upload',
    templateUrl: './gallery-upload.component.html',
    styleUrls: ['./gallery-upload.component.scss']
})
export class GalleryUploadComponent implements OnInit {

    dropzoneActive: boolean = false;

    // State for dropzone CSS toggling
    isHovering: boolean;

    entityID: string;
    uploadingFile: boolean = false;

    // Save Ref URL in Firestore
    @Input() saveRef: any;
    @Output() refValues = new EventEmitter();

    constructor(
        public filesService: FilesService
    ) {

    }

    ngOnInit() {
        this.filesService.getUploadStatus().subscribe(value => {
            this.uploadingFile = value.uploading;
            // console.log(this.uploadingFile );
        });
    }

    toggleHover(event: boolean) {
        this.isHovering = event;
    }

    handleDrop(fileList) {
        if (this.saveRef.origin === 'Properties Gallery') {
            if ((this.saveRef.fileLengthRef + fileList.length) > this.saveRef.maxFileLimitRef) {
                console.log(`Maximum files exceeded! Only ${this.saveRef.maxFileLimitRef} images allowed`);
                return false;
            } else {
                if (fileList) {
                    const uploadFiles = this.filesService.handleGalleryDrop(fileList, this.saveRef);
                    return Promise.resolve(uploadFiles).then(() => {
                        this.filesService.getUploadStatus().subscribe(values => {
                            this.refValues.emit(values);
                        });
                    });
                }
            }
        } else {
            if (fileList) {
                const uploadFiles = this.filesService.handleGalleryDrop(fileList, this.saveRef);
                return Promise.resolve(uploadFiles).then(() => {
                    this.filesService.getUploadStatus().subscribe(values => {
                        this.refValues.emit(values);
                    });
                });
            }
        }
    }

    refValue(file) { // You can give any function name
        const fileDetails = file;
        this.refValues.emit(fileDetails);
    }

    // Determines if the upload task is active
    isActive(snapshot) {
        return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes;
    }
}
