import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { Observable, BehaviorSubject } from 'rxjs';
import { tap, finalize } from 'rxjs/operators';
import { Upload } from './upload';
import * as _ from "lodash";
import { AuthenticationService } from 'src/app/auth/_services';
import { GenerateThumbnailService } from '../../_services/generate-thumbnail.service';
import { config } from '../../../_shared/_configs/config';

@Injectable()
export class FilesService {

    loggedInUser = {
        uid: '',
        userFirstname: '',
        userSurname: ''
    };
    userId: string;

    currentUpload: Upload;
    dropzoneActive: boolean = false;
    // Main task
    task: AngularFireUploadTask;

    // Progress monitoring
    percentage: Observable<number>;
    snapshot: Observable<any>;

    // Download URL
    downloadURL: Observable<string>;

    // State for dropzone CSS toggling
    isHovering: boolean;

    entityID: string;
    private uploadingFile: BehaviorSubject<any>;
    fileUploaded: boolean = false;

    constructor(
        private storage: AngularFireStorage,
        public generateThumbnailsService: GenerateThumbnailService,
        public afStore: AngularFirestore,
        private auth: AuthenticationService
    ) {
        this.entityID = config.entityId;
        this.uploadingFile = new BehaviorSubject<boolean>(false);
        this.auth.user.subscribe(userDetails => {
            if (userDetails) {
                this.userId = userDetails.uid;
                // Get Logged in User
                this.loggedInUser.uid = userDetails.uid;
                this.loggedInUser.userFirstname = userDetails.firstname;
                this.loggedInUser.userSurname = userDetails.surname;
            }
        });
    }

    public getUploadStatus(): Observable<any>  {
        return this.uploadingFile.asObservable();
    }

    public getFiles(filesRef) {
        return filesRef.valueChanges({idField: 'id'});
    }

    public removeFile(fileRef, fileId:string) {
        return fileRef.doc(fileId).update({active: false});
    }

    handleDrop(fileList: FileList, saveRef) {
        let filesIndex = _.range(fileList.length);

        _.each(filesIndex, (idx) => {
            this.currentUpload = new Upload(fileList[idx]);
            this.startUpload(this.currentUpload, saveRef)
        }
        )
    }

    startUpload(upload: Upload, saveRef) {
        this.setUploadStatus(true, upload.file.name);
        const fileName = `${new Date().getTime()}_${upload.file.name}`;
        const path = `${saveRef.filePath}.jpg`;
        const task = this.storage.upload(path, upload.file);
        const ref = this.storage.ref(path);
        this.percentage = task.percentageChanges();
        this.snapshot = task.snapshotChanges().pipe(
            tap(snap => {
                if (snap.bytesTransferred === snap.totalBytes) {
                }
            })
        );

        let reader = new FileReader();
        reader.readAsDataURL(upload.file);
        reader.onerror = (error) => {
            error;
        };
        return reader.onload = () => {
            return new Promise((resolve, reject) => {
                task.snapshotChanges().pipe(
                    finalize(() => {
                        const downloadURL = ref.getDownloadURL()
                        downloadURL.subscribe(url => {
                            if (upload.file.type.indexOf('image') !== -1) {
                                this.generateThumbnailsService.generateFromImage(reader.result, saveRef.thumbSize, saveRef.thumbSize, 1, thumbnailData => {
                                  const thumbPath = `${saveRef.filePath}_thumb.jpg`;
                                  const thumbRef = this.storage.ref(thumbPath);
                                  const thumbTask = thumbRef.putString(thumbnailData, 'data_url');
                                  return new Promise((resolve, reject) => {
                                      thumbTask.snapshotChanges().pipe(
                                          finalize(() => {
                                              const downloadThumbURL = thumbRef.getDownloadURL()
                                              downloadThumbURL.subscribe(thumbUrl => {

                                                let file = {};
                                                if (saveRef.uploadType === 'cropper') {
                                                  file = {
                                                    uploadedPhoto: url,
                                                    uploadedPhotoThumbnail: thumbUrl
                                                  }

                                                  this.saveSingleDataFirestore(saveRef, file)
                                                      .then(() => {
                                                          this.setUploadStatus(false, upload.file.name);
                                                          this.fileUploaded = true;
                                                          resolve();
                                                      });

                                                } else {
                                                  file = {
                                                    downloadFile: url,
                                                    downloadFileThumbnail: thumbUrl,
                                                    name: upload.file.name,
                                                    path: path,
                                                    path_thumb: thumbPath,
                                                    size: upload.file.size.toString(),
                                                    created: new Date().getTime(),
                                                    createdByUID: this.loggedInUser.uid,
                                                    createdByName: this.loggedInUser.userFirstname + ' ' + this.loggedInUser.userSurname,
                                                    filetype: upload.file.type,
                                                    active: true
                                                  }

                                                  this.saveFileDataFirestore(saveRef, file)
                                                      .then(() => {
                                                          this.setUploadStatus(false, upload.file.name);
                                                          this.fileUploaded = true;
                                                          resolve();
                                                      });
                                                }

                                              });
                                          })
                                      ).subscribe();
                                  });
                                });
                            } else {
                                const file = {
                                    downloadFile: url,
                                    name: upload.file.name,
                                    path: path,
                                    size: upload.file.size.toString(),
                                    created: new Date().getTime(),
                                    createdByUID: this.loggedInUser.uid,
                                    createdByName: this.loggedInUser.userFirstname + ' ' + this.loggedInUser.userSurname,
                                    filetype: upload.file.type,
                                    active: true
                                }

                                this.saveFileDataFirestore(saveRef, file)
                                    .then(() => {
                                        this.setUploadStatus(false, upload.file.name);
                                        this.fileUploaded = true;
                                        resolve();
                                    });
                            }
                        });
                    })
                ).subscribe();
            });
        };
    }

    handleGalleryDrop(fileList: FileList, saveRef, featuredId?) {
        let filesIndex = _.range(fileList.length);

        _.each(filesIndex, (idx) => {
            this.currentUpload = new Upload(fileList[idx]);
            this.startGalleryUpload(this.currentUpload, saveRef[idx], featuredId);
        })
    }

    startGalleryUpload(upload: Upload, saveRef, featuredId?) {
        this.setUploadStatus(true, upload.file.name);

        let reader = new FileReader();
        reader.readAsDataURL(upload.file);
        reader.onerror = (error) => {
            error;
        };
        return reader.onload = () => {
            return new Promise((resolve, reject) => {
                this.generateThumbnailsService.generateFromImage(reader.result, 700, 700, 1, imageData => {
                    const fileName = `${new Date().getTime()}_${upload.file.name}`;
                    const path = `${saveRef.filePath}/${fileName}`;
                    const ref = this.storage.ref(path);
                    const task = ref.putString(imageData, 'data_url');
                    this.snapshot = task.snapshotChanges().pipe(
                        tap(snap => {
                            if (snap.bytesTransferred === snap.totalBytes) {
                            }
                        })
                    );
                    return new Promise((resolve, reject) => {
                        task.snapshotChanges().pipe(
                            finalize(() => {
                                const downloadURL = ref.getDownloadURL()
                                downloadURL.subscribe(url => {
                                    this.generateThumbnailsService.generateThumbFromImage(reader.result, 370, 370, 1, thumbnailData => {
                                        const thumbFileName = `${new Date().getTime()}_thumb_${upload.file.name}`;
                                        const thumbPath = `${saveRef.filePath}/${thumbFileName}`;
                                        const thumbRef = this.storage.ref(thumbPath);
                                        const thumbTask = thumbRef.putString(thumbnailData, 'data_url');
                                        return new Promise((resolve, reject) => {
                                            thumbTask.snapshotChanges().pipe(
                                                finalize(() => {
                                                    const downloadThumbURL = thumbRef.getDownloadURL()
                                                    downloadThumbURL.subscribe(thumbUrl => {
                                                        const file = {
                                                            downloadFile: url,
                                                            name: upload.file.name,
                                                            downloadFileThumbnail: thumbUrl,
                                                            path: path,
                                                            path_thumb: thumbPath,
                                                            size: upload.file.size.toString(),
                                                            created: new Date().getTime(),
                                                            createdByUID: this.loggedInUser.uid,
                                                            createdByName: this.loggedInUser.userFirstname + ' ' + this.loggedInUser.userSurname,
                                                            filetype: upload.file.type,
                                                            active: true,
                                                            order: saveRef.order
                                                        }

                                                        this.saveGalleryFileDataFirestore(saveRef, file, featuredId)
                                                            .then(() => {
                                                                this.setUploadStatus(false, upload.file.name);
                                                                this.fileUploaded = true;
                                                                resolve();
                                                            });
                                                    });
                                                })
                                            ).subscribe();
                                        });
                                    });
                                });
                            })
                        ).subscribe();
                    });
                });
            });
        };
    }

    saveGalleryFileDataFirestore(saveRef, upload: Upload, featuredId?) {
        return this.saveFileDataFirestore(saveRef, upload).then(ref => {
          if (featuredId) {
            if (featuredId === saveRef.tempId) {
              const saveDoc = this.afStore.doc(saveRef.feautureImgDoc);
              return saveDoc.set({
                featureImage: upload.downloadFile,
                featureImageThumbnail: upload.downloadFileThumbnail,
                featureImageId: ref.id
              }, { merge: true });
            }
          }
        });
    }

    public setUploadStatus(newValue: boolean, fileName: string): void {
        const values = {
            uploading: newValue,
            fileName
        }
        this.uploadingFile.next(values);
    }

    saveFileDataFirestore(saveRef, upload: Upload) {

        const collectionRef = this.afStore.collection(saveRef.dbPath); //ref

        return collectionRef.add(upload)
            .then(ref => {
                ref.update({
                    ref: ref
                });

                return ref;
            });
    }

  saveSingleDataFirestore(saveRef, upload: Upload) {

    const collectionRef = this.afStore.doc(saveRef.dbPath); //ref

    return collectionRef.set(upload, { merge: true })
        .then(ref => {
            return ref;
        });
  }

  // CONVERT BASE64 TO FILE
  public dataUrlToFileList(dataUrls: string[], fileNames: string[], fileTypes: string[]): FileList {
    const fileArray: File[] = [];

    for (let index = 0; index < dataUrls.length; index++) {
        const dataUrl = dataUrls[index];
        const fileName = fileNames[index];
        const fileType = fileTypes[index];
        // Converting content to Blob
        const blobObject = this.dataUrlToBlob(dataUrl);
        // Converting Blob to file
        const file = new File([blobObject], fileName, { type: fileType });
        fileArray.push(file);
    }

    // Converting array with file to filelist and passing to uploader
    return fileArray as unknown as FileList; // < -------------- MAGIC HAPPENS HERE
  }

  private dataUrlToBlob(dataUrl: string): Blob {
      // convert base64 to raw binary data held in a string
      // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
      const byteString = atob(dataUrl.split(",")[1]);

      // separate out the mime component
      const mimeString = dataUrl.split(",")[0].split(":")[1].split(";")[0];

      // write the bytes of the string to an ArrayBuffer
      const ab = new ArrayBuffer(byteString.length);

      // create a view into the buffer
      const ia = new Uint8Array(ab);

      // set the bytes of the buffer to the correct values
      for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
      }

      // write the ArrayBuffer to a blob, and you're done
      const blob = new Blob([ia], { type: mimeString });
      return blob;
  }

}
