import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { Router } from "@angular/router";
import { config } from '../../../../../_shared/_configs/config';
import { environment } from 'src/environments/environment';
import { combineLatest, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { FilesService } from 'src/app/_shared/_components/files/files.service';
import { AuthenticationService } from 'src/app/auth/_services';
import { UsersService } from '../../users/_services/users.service';
import { NotificationsService } from 'src/app/_shared/_services/notifications.service';

declare var toastr: any;

@Injectable()
export class LocationsService {

  entityId: string;

  authSubscription: Subscription;
  loggedInUser: any;

  constructor(
    public afs: AngularFirestore,
    public router: Router,
    private filesService: FilesService,
    private auth: AuthenticationService,
    private usersService: UsersService,
    private notificationsService: NotificationsService
  ) {
    this.entityId = config.entityId;
    this.authSubscription = this.auth.user.subscribe(userDetails => {
      if (userDetails) {
        this.loggedInUser = userDetails;
      }
    });
  }

  getLocations() {
    const locationsCollection = this.afs.collection(`entities/${this.entityId}/locations`, ref => ref.orderBy('name', 'asc'));
    return locationsCollection.valueChanges({ idField: 'id' });
  }

  getPublicLocations() {
    const locationsCollection = this.afs.collection(`public/${this.entityId}/locations`, ref =>
          ref.orderBy('name', 'asc').where('status', '==', 1).where('verified', '==', true).where('active', '==', true));
    return locationsCollection.valueChanges({ idField: 'id' });
  }

  getPublicLocationsExtraData(locationId) {
    const getLocationAmenities = this.afs.doc(`/public/${config.entityId}/locations/${locationId}/amenities/list`).valueChanges();
    const getLocationBusinessHours = this.afs.doc(`/public/${config.entityId}/locations/${locationId}/businessHours/list`).valueChanges();
    const getLocationGallery = this.afs.collection(`/public/${config.entityId}/locations/${locationId}/gallery`, ref => ref.orderBy('order', 'asc')).valueChanges({ idField: 'uid' });
    const getLocationRooms = this.afs.collection(`/public/${config.entityId}/locations/${locationId}/meetingRooms`, ref => ref.where('active', '==', true)).valueChanges();
    return combineLatest([getLocationAmenities, getLocationBusinessHours, getLocationGallery, getLocationRooms]).pipe(
      map((dataArrays) => {
        let locationData = {
          amenities: dataArrays[0],
          businessHours: dataArrays[1],
          gallery: dataArrays[2],
          meetingRooms: dataArrays[3]
        };
        return locationData;
      })
    );
  }

  getLocationsExtraData(locationId) {
    const getLocationAmenities = this.afs.doc(`/entities/${config.entityId}/locations/${locationId}/amenities/list`).valueChanges();
    const getLocationBusinessHours = this.afs.doc(`/entities/${config.entityId}/locations/${locationId}/businessHours/list`).valueChanges();
    const getLocationGallery = this.afs.collection(`/entities/${config.entityId}/locations/${locationId}/gallery`, ref => ref.orderBy('order', 'asc')).valueChanges({ idField: 'uid' });
    const getLocationRooms = this.afs.collection(`/entities/${config.entityId}/locations/${locationId}/meetingRooms`, ref => ref.where('active', '==', true)).valueChanges();
    return combineLatest([getLocationAmenities, getLocationBusinessHours, getLocationGallery, getLocationRooms]).pipe(
      map((dataArrays) => {
        let locationData = {
          amenities: dataArrays[0],
          businessHours: dataArrays[1],
          gallery: dataArrays[2],
          meetingRooms: dataArrays[3]
        };
        return locationData;
      })
    );
  }

  getLocationsForUser(userId) {
    return this.afs.collection(`users/${userId}/entities/${config.entityId}/locations/`, ref => ref.where('active', '==', true)).valueChanges();
  }

  getLocationTypes(field, value) {
    const locationsCollection = this.afs.collection(`entities/${this.entityId}/locations`, ref => ref.where(field, '==', value));
    return locationsCollection.valueChanges({ idField: 'id' });
  }

  getLocationDetails(locationId) {
    const getLocationDetails = this.afs.doc(`/entities/${config.entityId}/locations/${locationId}`).valueChanges();
    const getLocationAmenities = this.afs.doc(`/entities/${config.entityId}/locations/${locationId}/amenities/list`).valueChanges();
    const getLocationBusinessHours = this.afs.doc(`/entities/${config.entityId}/locations/${locationId}/businessHours/list`).valueChanges();
    const getLocationGallery = this.afs.collection(`/entities/${config.entityId}/locations/${locationId}/gallery`, ref => ref.orderBy('order', 'asc')).valueChanges({ idField: 'uid' });
    const getLocationRooms = this.afs.collection(`/entities/${config.entityId}/locations/${locationId}/meetingRooms`, ref => ref.where('active', '==', true)).valueChanges();
    return combineLatest([getLocationDetails, getLocationAmenities, getLocationBusinessHours, getLocationGallery, getLocationRooms]).pipe(
      map((dataArrays) => {
        let locationData = {
          location: dataArrays[0],
          amenities: dataArrays[1],
          businessHours: dataArrays[2],
          gallery: dataArrays[3],
          meetingRooms: dataArrays[4]
        };
        return locationData;
      })
    );
  }

  getPublicLocationsBySlug(slug) {
    const locationsCollection = this.afs.collection(`public/${this.entityId}/locations`, ref => ref.where('slug', '==', slug));
    return locationsCollection.valueChanges({ idField: 'id' });
  }

  getPublicLocationsDetails(locationId) {
    const getLocationDetails = this.afs.doc(`/public/${config.entityId}/locations/${locationId}`).valueChanges();
    const getLocationAmenities = this.afs.doc(`/public/${config.entityId}/locations/${locationId}/amenities/list`).valueChanges();
    const getLocationBusinessHours = this.afs.doc(`/public/${config.entityId}/locations/${locationId}/businessHours/list`).valueChanges();
    const getLocationGallery = this.afs.collection(`/public/${config.entityId}/locations/${locationId}/gallery`, ref => ref.orderBy('order', 'asc')).valueChanges({ idField: 'uid' });
    const getLocationMeetingRooms = this.afs.collection(`public/${config.entityId}/locations/${locationId}/meetingRooms`).valueChanges();
    return combineLatest([getLocationDetails, getLocationAmenities, getLocationBusinessHours, getLocationGallery, getLocationMeetingRooms]).pipe(
      map((dataArrays) => {
        let locationData = {
          location: dataArrays[0],
          amenities: dataArrays[1],
          businessHours: dataArrays[2],
          gallery: dataArrays[3],
          meetingRooms: dataArrays[4]
        };
        return locationData;
      })
    );
  }

  getLocationUsers(locationId) {
    const locationUsersCollection = this.afs.collection(`entities/${this.entityId}/locations/${locationId}/users`, ref => ref.where('active', '==', true));
    return locationUsersCollection.valueChanges({ idField: 'id' });
  }

  addLocation(locationInfo) {

    const locationsCollection = this.afs.collection(`entities/${this.entityId}/locations`);
    let members = locationInfo.users;

    // SET VERIFIED LOCATION
    let verified;
    if (config.source === 'admin') {
      verified = true;
    } else {
      verified = false;
    }
    let customType = '';
    if (locationInfo.basicInfo.typeOther) {
      customType = locationInfo.basicInfo.typeOther
    }

    // SET PREFIX FOR REF NO
    const referencePrefix = locationInfo.basicInfo.name.substring(0, 3).toUpperCase();

    // SET PREFIX FOR SLUG
    const slugPrefix = locationInfo.basicInfo.name.replace(/\s/g, '-').toLowerCase();

    // SET LOCATION DOC DATA
    let locationData = {
      active: true,
      created: new Date(),
      createdBy: this.loggedInUser.uid,
      createdByName: `${this.loggedInUser.firstname} ${this.loggedInUser.surname}`,
      name: locationInfo.basicInfo.name,
      type: locationInfo.basicInfo.type,
      customType: customType,
      description: locationInfo.basicInfo.description,
      contactNumber: locationInfo.basicInfo.contactNumber,
      availableSpaces: locationInfo.basicInfo.availableSpaces,
      meetingRooms: locationInfo.basicInfo.meetingRooms,
      noiseLevel: locationInfo.basicInfo.noiseLevel,
      eventSpace: locationInfo.basicInfo.eventSpace,
      parking: locationInfo.basicInfo.parking,
      address: locationInfo.locationAddress,
      status: 0,
      referencePrefix: referencePrefix,
      owner: locationInfo.owner,
      source: config.source,
      verified: verified,
      featureImage: '',
      featureImageThumbnail: '',
      featureImageId: '',
      uploadedPhoto: '',
      uploadedPhotoThumbnail: '',
      product: '',
      slugPrefix: slugPrefix
    }

    locationInfo.name = locationInfo.basicInfo.name;
    locationInfo.description = locationInfo.basicInfo.description;

    return locationsCollection.add(locationData).then(ref => {
      const locationsDoc = this.afs.doc(`entities/${this.entityId}/locations/${ref.id}`);
      const amenitiesDoc = this.afs.doc(`entities/${this.entityId}/locations/${ref.id}/amenities/list`);
      const businessHoursDoc = this.afs.doc(`entities/${this.entityId}/locations/${ref.id}/businessHours/list`);
      const requestDoc = this.afs.doc(`pending/${ref.id}`);

      return locationsDoc.set({ uid: ref.id }, { merge: true }).then(() => {

        // SET AMENITIES
        const setAmenities = amenitiesDoc.set({ list: locationInfo.amenities }, { merge: true });

        // SET BUSINESS HOURS
        const setBusinessHours = businessHoursDoc.set({
          monday: locationInfo.businessHours.monday,
          tuesday: locationInfo.businessHours.tuesday,
          wednesday: locationInfo.businessHours.wednesday,
          thursday: locationInfo.businessHours.thursday,
          friday: locationInfo.businessHours.friday,
          saturday: locationInfo.businessHours.saturday,
          sunday: locationInfo.businessHours.sunday
        }, { merge: true });

        // SET LOCATION REQUEST
        const encodedBase64Data = btoa(`/locations/edit/${ref.id}`);
        const locationUrl = `${environment.adminUrl}/redirect/${encodedBase64Data}`;
        const adminRequest = requestDoc.set({
          request: 'locationAddRequest',
          locationName: locationData.name,
          locationId: ref.id,
          locationAddress: locationData.address.formatted_address,
          locationOwner: locationData.owner,
          redirectUrl: locationUrl,
          source: config.source,
        }, { merge: true });

        return Promise.all([setAmenities, setBusinessHours, adminRequest]).then(() => {

          // SAVE LOGO
          if (locationInfo.locationLogo) {

            // SET REF PATH TO SAVE
            let saveRef = {
              dbPath: `entities/${config.entityId}/locations/${ref.id}`,
              filePath: `entities/${config.entityId}/locations/${ref.id}/${ref.id}_logo`,
              uploadType: 'cropper',
              thumbSize: 150
            };

            const uploadFiles = this.filesService.handleDrop(locationInfo.locationLogo, saveRef);
            return Promise.resolve(uploadFiles).then(() => {
              if (locationInfo.gallery) {
                const uploaGallery = this.handleGalleryImages(locationInfo.gallery, locationInfo.featuredImg, ref.id);
                return Promise.resolve(uploaGallery).then(() => {
                  if (members) {
                    members.forEach(member => {
                      this.linkUsersAndLocations(member, locationData, ref.id);
                    });
                  }
                });
              } else {
                if (members) {
                  members.forEach(member => {
                    this.linkUsersAndLocations(member, locationData, ref.id);
                  });
                }
              }
            });
          } else {
            if (locationInfo.gallery) {
              const uploaGallery = this.handleGalleryImages(locationInfo.gallery, locationInfo.featuredImg, ref.id);
              return Promise.resolve(uploaGallery).then(() => {
                if (members) {
                  members.forEach(member => {
                    this.linkUsersAndLocations(member, locationData, ref.id);
                  });
                }
              });
            } else {
              if (members) {
                members.forEach(member => {
                  this.linkUsersAndLocations(member, locationData, ref.id);
                });
              }
            }
          }

        });
      })
    });

  }

  linkUsersAndLocations(member, locationData, refId) {

    // LINK USER TO TEAM
    if (member.invited) {
      return this.usersService.inviteUser(member).then(userRef => {
        if (locationData.owner.uid === member.uid) {
          member.isOwner = true;
        }

        // LINK USER TO TEAM
        let linkData = member;
        linkData.uid = userRef;
        linkData.locationId = refId;
        linkData.name = locationData.name;
        linkData.address = locationData.address.formatted_address;
        linkData.verified = locationData.verified;
        linkData.status = locationData.status;
        linkData.created = locationData.created;
        return this.addUserToLocation(linkData);
      });
    } else {
      if (locationData.owner.uid === member.uid) {
        member.isOwner = true;
      }

      // LINK USER TO TEAM
      let linkData = member;
      linkData.locationId = refId;
      linkData.name = locationData.name;
      linkData.address = locationData.address.formatted_address;
      linkData.verified = locationData.verified;
      linkData.status = locationData.status;
      linkData.created = locationData.created;
      return this.addUserToLocation(linkData);
    }
  }

  addUserToLocation(linkData) {

    const teamsUsersDoc = this.afs.doc(`entities/${this.entityId}/locations/${linkData.locationId}/users/${linkData.uid}`);
    const usersTeamsDoc = this.afs.doc(`users/${linkData.uid}/entities/${this.entityId}/locations/${linkData.locationId}`);

    let accepted = (linkData.isOwner) ? true : false;
    let sendUserNotification;

    // SET USER DATA FOR TEAM
    let locationUser = {
      active: true,
      firstname: linkData.firstname,
      surname: linkData.surname,
      email: linkData.email,
      ref: `/users/${linkData.uid}`,
      uid: linkData.uid,
      isAdmin: linkData.isAdmin,
      isOwner: linkData.isOwner,
      accepted: accepted
    }
    // SET TEAM DATA FOR USER
    let userTeam = {
      active: true,
      name: linkData.name,
      ref: `/entities/${this.entityId}/locations/${linkData.locationId}`,
      uid: linkData.locationId,
      isAdmin: linkData.isAdmin,
      isOwner: linkData.isOwner,
      address: linkData.address,
      verified: linkData.verified,
      status: linkData.status,
      created: linkData.created,
      accepted: accepted
    }

    const notification = {
      title: 'Added to Location',
      body: `You have been added as admin to ${linkData.name} location`,
      type: 'locationUserAdd',
      createdBy: this.loggedInUser.uid,
      addToHistory: true,
      userId: linkData.uid,
      customData: {
        createdBy: this.loggedInUser.uid,
        notificationUrl: `/locations/list`
      },
      historyInfo: {
        created: new Date(),
        title: 'Added to Location',
        message: `You have been added as admin to <strong>${linkData.name}</strong> location`,
        linkText: `View Locations`,
        type: 'locationUserAdd',
        createdBy: this.loggedInUser.uid,
        userId: linkData.uid,
        url: `/locations/list`,
        listRef: '/my-notifications',
        source: config.source,
        unread: true
      }
    }

    const setLocationUser = teamsUsersDoc.set(locationUser, { merge: true });
    const setUserLocation = usersTeamsDoc.set(userTeam, { merge: true });

    if (!accepted) {
      sendUserNotification = this.notificationsService.addNotification(notification, linkData);
    }

    return Promise.all([setLocationUser, setUserLocation, sendUserNotification]);
  }

  updateLocation(locationId, location, businessHours?, amenitiesList?) {
    const locationsDoc = this.afs.doc(`entities/${this.entityId}/locations/${locationId}`);
    return locationsDoc
      .set(location, { merge: true })
      .then(() => {
        if (businessHours) {
          this.updateLocationBusinessHours(locationId, businessHours);
        }
        if (amenitiesList) {
          this.updateAmenitiesHours(locationId, amenitiesList);
        }
      })
      .catch(error => {

      });
  }

  updateLocationBusinessHours(locationId, businessHours) {
    const businessHoursDoc = this.afs.doc(`entities/${this.entityId}/locations/${locationId}/businessHours/list`);
    return businessHoursDoc.set({
      monday: businessHours.monday,
      tuesday: businessHours.tuesday,
      wednesday: businessHours.wednesday,
      thursday: businessHours.thursday,
      friday: businessHours.friday,
      saturday: businessHours.saturday,
      sunday: businessHours.sunday
    }, { merge: true });
  }

  updateAmenitiesHours(locationId, amenitiesList) {
    const amenitiesDoc = this.afs.doc(`entities/${this.entityId}/locations/${locationId}/amenities/list`);
    return amenitiesDoc.set({ list: amenitiesList }, { merge: true });
  }

  updateLocationUser(userId, locationId, updateValues) {
    const teamsUsersDoc = this.afs.doc(`entities/${this.entityId}/locations/${locationId}/users/${userId}`);
    const usersTeamsDoc = this.afs.doc(`users/${userId}/entities/${this.entityId}/locations/${locationId}`);

    const setTeamUser = teamsUsersDoc.set(updateValues, { merge: true });
    const setUserTeam = usersTeamsDoc.set(updateValues, { merge: true });

    return Promise.all([setTeamUser, setUserTeam]);
  }

  handleGalleryImages(imagesArray, featuredImg, locationId) {
    const locationPhotosArray: any = [];
    let pathInfo = [];
    if (imagesArray.length > 0) {
      return imagesArray.forEach((image, index, array) => {
        let imgInfo = {
          filePath: `entities/${config.entityId}/locations/${locationId}`,
          dbPath: `entities/${config.entityId}/locations/${locationId}/gallery`,
          order: image.order,
          tempId: image.tempId,
          feautureImgDoc: `entities/${config.entityId}/locations/${locationId}`
        };
        locationPhotosArray.push(image.file);
        pathInfo.push(imgInfo);
        if (locationPhotosArray.length === array.length && pathInfo.length == array.length) {
          return this.filesService.handleGalleryDrop(locationPhotosArray, pathInfo, featuredImg);
        } else {
          return Promise.resolve('Failed to add images ');
        }
      })
    }
  }

  updateGalleryImageOrder(locationId, image) {
    const imageDoc = this.afs.doc(`entities/${this.entityId}/locations/${locationId}/gallery/${image.uid}`);
    return imageDoc.set({ order: image.order }, { merge: true });
  }

  deleteGalleryImage(locationId, imageId) {
    const imageDoc = this.afs.doc(`entities/${this.entityId}/locations/${locationId}/gallery/${imageId}`);
    return imageDoc.delete();
  }



  deleteLocationUser(userId, locationId, locationName) {
    const locationsUsersDoc = this.afs.doc(`entities/${this.entityId}/locations/${locationId}/users/${userId}`);
    const usersLocationsDoc = this.afs.doc(`users/${userId}/entities/${this.entityId}/locations/${locationId}`);

    const deletedUser = {
      uid: userId
    }

    const notification = {
      title: 'Removed from Location',
      body: `You have been removed from ${locationName} location`,
      type: 'locationUserRemoved',
      createdBy: this.loggedInUser.uid,
      addToHistory: true,
      userId: userId,
      customData: {
        createdBy: this.loggedInUser.uid,
        notificationUrl: `/locations/list`
      },
      historyInfo: {
        created: new Date(),
        title: 'Removed from Location',
        message: `You have been removed from <strong>${locationName}</strong> location`,
        linkText: `View Locations`,
        type: 'locationUserRemoved',
        createdBy: this.loggedInUser.uid,
        userId: userId,
        url: `/locations/list`,
        listRef: '/my-notifications',
        source: config.source,
        unread: true
      }
    }

    const sendUserNotification = this.notificationsService.addNotification(notification, deletedUser);
    const deleteLocationUser = locationsUsersDoc.delete();
    const deleteUserLocation = usersLocationsDoc.delete();

		return Promise.all([deleteLocationUser, deleteUserLocation, sendUserNotification]);
  }

  acceptLocationInvite(locationData, user, accepted) {
    const locationDoc = this.afs.doc(locationData.ref);
    const locationUsersDoc = this.afs.doc(`entities/${this.entityId}/locations/${locationData.uid}/users/${user.uid}`);
    const usersLocationsDoc = this.afs.doc(`users/${user.uid}/entities/${this.entityId}/locations/${locationData.uid}`);
    let locationOwner;
    return locationDoc.ref.get().then((location: any) => {

      locationOwner = location.data().owner;

      let notification = {
        title: 'Accepted Location Invite',
        body: `${user.firstname} ${user.surname} accepted your invite to ${locationData.name}`,
        type: 'locationUserAdded',
        createdBy: user.uid,
        addToHistory: true,
        userId: locationOwner.uid,
        customData: {
          createdBy: user.uid,
          notificationUrl: `/locations/edit/${locationData.uid}`
        },
        historyInfo: {
          created: new Date(),
          title: 'Accepted Location Invite',
          message: `${user.firstname} ${user.surname} accepted your invite to <strong>${locationData.name}</strong>`,
          linkText: `View Location`,
          type: 'locationUserAdded',
          createdBy: user.uid,
          userId: locationOwner.uid,
          url: `/locations/edit/${locationData.uid}`,
          listRef: '/my-notifications',
          source: config.source,
          unread: true
        }
      }

      if (!accepted) {
        notification.title = 'Declined Location Invite';
        notification.body = `${user.firstname} ${user.surname} declined your invite to ${locationData.name}`;
        notification.type = `locationUserDeleted`;
        notification.historyInfo.title = 'Declined Location Invite';
        notification.historyInfo.message = `${user.firstname} ${user.surname} declined your invite to <strong>${locationData.name}</strong>`;
        notification.historyInfo.type = `locationUserDeleted`;
      }

      const setLocationUser = (accepted) ? locationUsersDoc.set({accepted: accepted}, {merge:true}) : locationUsersDoc.delete();
      const setUserLocation = (accepted) ? usersLocationsDoc.set({accepted: accepted}, {merge:true}) : usersLocationsDoc.delete();
      const sendUserNotification = this.notificationsService.addNotification(notification, locationOwner);

      return Promise.all([setLocationUser, setUserLocation, sendUserNotification]);
    });
  }

}
