import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthenticationService } from '../../_services/authentication.service';
import { Subscription } from 'rxjs';
import { User } from '../../../theme/pages/wealth-spaces/users/_models/user.model';

@Component({
    selector: 'app-verification',
    templateUrl: './auth-verification.component.html'
})
export class AuthVerificationComponent implements OnInit {

    firebaseId: string;
    userVerificationSuccessfull: boolean = false;
    userVerifying: boolean = true;
    userSubscription: Subscription;
    user: User;

    constructor(
      private route: ActivatedRoute,
      public authService: AuthenticationService,
      private router: Router
    ) { }

    ngOnInit() {
      this.userSubscription = this.authService.user.subscribe(userDetails => {
        if (userDetails) {
          this.user = userDetails;
        }
      });
        this.route.params
            .subscribe(
                (params: Params) => {
                    this.firebaseId = params['uid'];
                    // console.log('firebaseId', this.firebaseId);

                    console.log(this.firebaseId);

                    // VERIFY USER EMAIL
                    this.authService.verifyUserEmail(this.firebaseId).then((data) => {
                      console.log(data);
                        // console.log('CC => UserVerificationComponent -> ngOnInit -> data', data);
                        this.userVerificationSuccessfull = true;
                        this.userVerifying = false;
                    }).catch((err) => {
                        console.log(err)
                        this.userVerificationSuccessfull = false;
                        this.userVerifying = false;
                    });
                }
            );
    }

}
