import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AngularFirestore } from "@angular/fire/firestore";
import { Router } from "@angular/router";
import { config } from '../_configs/config';
import { combineLatest, Subscription, Observable } from 'rxjs';
import { map, take, retry, catchError } from 'rxjs/operators';
import { FilesService } from 'src/app/_shared/_components/files/files.service';
import { AuthenticationService } from 'src/app/auth/_services';
import { UsersService } from 'src/app/theme/pages/wealth-spaces/users/_services/users.service';

@Injectable()
export class TransactionsService {

  entityId: string;

  authSubscription: Subscription;
  loggedInUser: any;

  // PAYMENT API DETAILS
  baseurl = 'https://secret-island-96823.herokuapp.com';

  constructor(
    public afs: AngularFirestore,
    public router: Router,
    private filesService: FilesService,
    private auth: AuthenticationService,
    private usersService: UsersService,
    private http: HttpClient
  ) {
    this.entityId = config.entityId;
    this.authSubscription = this.auth.user.subscribe(userDetails => {
      if (userDetails) {
        this.loggedInUser = userDetails;
      }
    });
  }

  addTransaction(subInfo, proRataAmount, onceOff?, onceOffId?) {

    console.log(subInfo);

    const transactionsCollection = this.afs.collection(`entities/${this.entityId}/transactions`);
    let userDoc;
    let teamDoc;

    let subData;

    if (subInfo.user) {
      subData = {
        active: true,
        created: new Date(),
        createdByName: `${subInfo.user.firstname} ${subInfo.user.surname}`,
        createdBy: subInfo.user.uid,
        paidByName: `${subInfo.user.firstname} ${subInfo.user.surname}`,
        paidBy: subInfo.user.uid,
        status: 1,
        type: subInfo.type,
        typeId: subInfo.typeId,
        typeName: subInfo.product.title,
        amount: proRataAmount,
        entityId: config.entityId,
        source: config.source
      }
    }

    if (subInfo.team) {
      subData = {
        active: true,
        created: new Date(),
        createdByName: `${subInfo.payerInfo.firstname} ${subInfo.payerInfo.surname}`,
        createdBy: subInfo.payerInfo.uid,
        paidByName: subInfo.team.name,
        paidBy: subInfo.team.uid,
        status: 1,
        type: subInfo.type,
        typeId: subInfo.typeId,
        typeName: subInfo.product.title,
        amount: proRataAmount,
        entityId: config.entityId,
        source: config.source
      }
    }

    if (onceOff) {
      subData = {
        active: true,
        created: new Date(),
        createdByName: subInfo.createdByName,
        createdBy: subInfo.createdBy,
        paidByName: subInfo.createdByName,
        paidBy: subInfo.createdBy,
        status: 1,
        type: subInfo.type,
        typeId: onceOffId,
        typeName: subInfo.name,
        amount: subInfo.price,
        entityId: config.entityId,
        source: config.source
      }
    }

    return transactionsCollection.add(subData).then(ref => {

      const transactionsDoc = this.afs.doc(`entities/${this.entityId}/transactions/${ref.id}`);
      subData.uid = ref.id;
      return transactionsDoc.set(subData, { merge: true }).then(() => {

        if (subInfo.user) {
          userDoc = this.afs.doc(`users/${subInfo.user.uid}/entities/${this.entityId}/transactions/${ref.id}`);
          return userDoc.set(subData, { merge: true }).then(() => {
            return ref.id;
          });
        }

        if (subInfo.team) {
          teamDoc = this.afs.doc(`entities/${this.entityId}/teams/${subInfo.team.uid}/transactions/${ref.id}`).set(subData, { merge: true });
          userDoc = this.afs.doc(`users/${subInfo.payerInfo.uid}/entities/${this.entityId}/transactions/${ref.id}`).set(subData, { merge: true });
          return Promise.all([teamDoc, userDoc]).then(() => {
            return ref.id;
          });
        }

        if (onceOff) {
          userDoc = this.afs.doc(`users/${subInfo.createdBy}/entities/${this.entityId}/transactions/${ref.id}`);
          return userDoc.set(subData, { merge: true }).then(() => {
            return ref.id;
          });
        }

      });
    });

  }

  getTransaction(transactionId) {
    const transactionDoc = this.afs.doc(`entities/${this.entityId}/transactions/${transactionId}`);
    return transactionDoc.valueChanges();
  }

  getPendingTransaction(transactionId) {
    const transactionCollection = this.afs.collection(`pendingTransactions`, ref => ref.where('transactionId', '==', transactionId));
    return transactionCollection.snapshotChanges().pipe(map(changes => {
      if (changes.length > 0) {
        const data = changes[0].payload.doc.data() as any;
        data.id = changes[0].payload.doc.id;
        return data;
      }
    }));
  }

  deletePendingTransaction(transactionId) {
    const transactionDoc = this.afs.doc(`pendingTransactions/${transactionId}`).delete();
    return transactionDoc;
  }

  updateTransaction(transactionId, status) {
    const transactionDoc = this.afs.doc(`entities/${this.entityId}/transactions/${transactionId}`);
    return transactionDoc.set({status: status}, {merge: true}).then(ref => {
      return transactionDoc.ref.get().then(transaction => {
        return transaction.data();
      });
    });
  }

  getTransactions(type) {
    const transactionsCollection = this.afs.collection(`${type}/transactions`, ref => ref.orderBy('created', 'desc').where('active', '==', true));
    return transactionsCollection.snapshotChanges().pipe(map(changes => {
      return changes.map(a => {

        const data = a.payload.doc.data() as any;

        // FETCH EXTRA INFO
        if (data.type === 'userSubscription' || data.type === 'teamSubscription') {
          if (data.status !== 1) {
            this.afs.doc(`${type}/subscriptions/${data.typeId}`).ref.get().then((subscription: any) => {
              if (subscription.data()) {
                data.subscription = subscription.data();
              }
            });
          } else {
            this.afs.doc(`pendingSubscriptions/${data.typeId}`).ref.get().then((subscription: any) => {
              if (subscription.data()) {
                data.subscription = subscription.data();
              }
            });
          }
        }
        if (data.type === 'meetingRoom') {
          this.afs.doc(`${type}/bookings/${data.typeId}`).ref.get().then((booking: any) => {
            if (booking.data()) {
              data.booking = booking.data();
            }
          });
        }
        return data;
      });
    }));
  }

  // USER CARDS
  getUserCards(userId) {
    const cardsCollection = this.afs.collection(`entities/${this.entityId}/cardDetails`, ref => ref.where('userId', '==', userId));
    return cardsCollection.valueChanges({ idField: 'id' });
  }

  setDefaultCard(cardId, userId?) {
    const cardsDoc = this.afs.doc(`entities/${this.entityId}/cardDetails/${cardId}`);
    if (userId) {
      const cardsCollection = this.afs.collection(`entities/${this.entityId}/cardDetails`, ref => ref.where('userId', '==', userId));
      return cardsCollection.ref.get().then(userCards => {
        if (userCards.size > 0) {
          userCards.forEach(card => {
            if (card.ref.id !== cardId) {
              card.ref.set({ default: false }, {merge: true});
            } else if (card.ref.id === cardId) {
              card.ref.set({ default: true }, {merge: true});
            }
          });
        }
      });
    } else {
      return cardsDoc.set({ default: true }, {merge: true});
    }
  }


  deleteCard(cardId) {
    const pendingCollection = this.afs.collection(`pending`);
    const cardInfo = {
      request: 'deleteUserCard',
      source: config.source,
      entityId: config.entityId,
      userId: this.loggedInUser.uid,
      cardId: cardId,
      url: `${this.baseurl}/delete-card`
    }
    return pendingCollection.add(cardInfo).then(ref => {
      return ref.id;
    });
  }

  getDeletedCard(cardId) {
    return this.afs.doc(`pending/${cardId}`).valueChanges();
  }

  // INVOICES
  createTransactionInvoice(transaction) {

  }

}
