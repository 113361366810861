import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QRCodeModule } from 'angularx-qrcode';
import { QrCodeComponent } from './qr-code.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        QRCodeModule
    ],
    declarations: [
        QrCodeComponent
    ],
    exports: [
        QrCodeComponent
    ]
})
export class QrcModule { }
