import { Component, OnInit, Input } from '@angular/core';
import { StorageService } from 'src/app/_shared/_services/storage.service';

declare var $: any;

@Component({
  selector: 'app-modal-users',
  templateUrl: './modal-users.html'
})
export class ModalUsersComponent implements OnInit {

  @Input() modalData: any;

  constructor(
    public storageService: StorageService
  ) {
  }

  ngOnInit() {
    $("#usersModal").modal("show");
  }

  ngOnDestroy() {
  }

  onModalHide() {
    $('#usersModal').on("hidden.bs.modal", () => {
      this.destroyModal();
    });
  }

  hideModal() {
    $('#usersModal').modal('hide');
  }

  closeModal(value) {
    if (value) {
      this.hideModal();
    }
  }

  destroyModal() {
    const modalInfo = {};
    this.storageService.openModal(modalInfo);
  }

}
