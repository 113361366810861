import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AppCoreModule } from 'src/app/_shared/app-core.module';
import { StickyNavComponent } from './sticky-nav/sticky-nav.component';
import { FooterComponent } from './footer/footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [
      StickyNavComponent,
      FooterComponent
    ],
    exports: [
      StickyNavComponent,
      FooterComponent
    ],
    imports: [
      CommonModule,
      FormsModule,
      RouterModule,
      NgxPermissionsModule.forChild(),
      AppCoreModule
    ]
})
export class LayoutModule {
}
