import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "ws-messages",
  templateUrl: "./ws-messages.component.html",
  styleUrls: ["./ws-messages.component.scss"],
})
export class WSMessagesComponent implements OnInit {
  @Input() conversationId: any;
  
  constructor() {}

  ngOnInit() {

  }

  ngOnDestroy() {

  }
}
