import { Injectable } from '@angular/core';
import {
    AngularFirestore,
    AngularFirestoreCollection,
    AngularFirestoreDocument
} from '@angular/fire/firestore';
import { Observable, Subject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../auth/_services/authentication.service';
import { User } from '../../theme/pages/wealth-spaces/users/_models/user.model';
import { QuerySnapshot } from '@firebase/firestore-types';
import { map } from 'rxjs/operators';
import { config } from '../_configs/config';

@Injectable()
export class NotificationsService {

  loggedInUser: User;
  entityId: string;

  constructor(
    public auth: AuthenticationService,
    public afs: AngularFirestore,
    public router: Router
  ) {
      //FETCH LOGGED IN USER
      this.auth.user.subscribe(userDetails => {
          if (userDetails) {
              this.loggedInUser = userDetails;
          }
      });

      this.entityId = config.entityId;

  }

  addNotification(notification, user?) {
    const sendToUser = (user) ? user : this.loggedInUser;
    const addNotificationHistory = this.afs.collection(`/pending`).add({
        request: 'sendUserPushNotification',
        notification: notification,
        user: sendToUser,
        entityId: config.entityId
    });
    return addNotificationHistory;
  }

  addUserNotification(notification, reference) {
    const addNotificationHistory = this.afs.collection(reference).add(notification);
    return addNotificationHistory;
  }

  getUserNotifications(userId) {
    const notificationCollection = this.afs.collection(`users/${userId}/entities/${this.entityId}/notifications`, ref => ref.orderBy('created', 'desc').where('unread', '==', true));
    return notificationCollection.valueChanges({ idField: 'id' });
  }

  getAllNotifications(type) {
    const notificationCollection = this.afs.collection(`${type}/notifications`, ref => ref.orderBy('created', 'desc'));
    return notificationCollection.valueChanges({ idField: 'id' });
  }

  updateNotifiction(notificationId, data) {
    const notificationDoc = this.afs.doc(`users/${this.loggedInUser.uid}/entities/${this.entityId}/notifications/${notificationId}`);
    return notificationDoc.set(data, {merge: true});
  }

  markAllAsRead(type) {
    const notificationCollection = this.afs.collection(`${type}/notifications`);
    return notificationCollection.ref.get().then(notifications => {
      if (notifications.size > 0) {
        notifications.forEach(notification => {
          notification.ref.set({ unread: false }, {merge: true});
        });
      }
    });
  }

}
