import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Location, DatePipe } from '@angular/common';
import { FormGroup, FormBuilder, Validators, AbstractControl, ValidatorFn, FormArray, NgForm, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { config } from '../../../../../_configs/config';
import { BookingsService } from '../../_services/bookings.service';
import { Subscription } from 'rxjs';
import { User } from 'src/app/theme/pages/wealth-spaces/users/_models/user.model';
import * as moment from 'moment';

declare var $: any;

@Component({
    selector: 'ws-bookings-edit-locations-form',
    templateUrl: './bookings-edit-location.form.html'
})
export class WSBookingsEditLocationFormComponent implements OnInit, OnDestroy {

  @Input() bookingData: any;
  @Input() bookingType: any;
  @Input() locations: any;

  @Output() closeModal = new EventEmitter();

  isLoading: boolean = false;
  type: string;

  // FORM
  bookingEditForm: FormGroup;
  isSubmitting: boolean = false;
  removeUpdate: boolean = false;
  showDeleteButtons: boolean = false;

  // LOCATIONS
  locationId: string;
  location: any;
  locationAddress: string;
  locationTypes: any[];

  // DATE
  minDate = undefined;
  bookingDate: any;
  bookingLocation: any;
  bookingTime: any;
  oldBookingDate: any;
  bookingStatus: any;

  // USER
  bookingUserInfo: User;

  // BOOKING MESSAGE
  showMessage: boolean = false;
  messagePlaceholder: string;

  // ACTIVATE BOOKING
  showActiveMessage: boolean = false;

  // NOTIFICATION
  sendNotification: boolean = false;
  notificationType: string;

  constructor(
    private fb: FormBuilder,
    private bookingsService: BookingsService,
    private datePipe: DatePipe,
    private toastr: ToastrService
  ) {
  }

  ngOnInit() {
    this.isLoading = true;
    this.buildForm();
    // SET DATE RANGE
    const current = new Date();
    this.minDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate()
    };
    // GET BOOKING INFO
    this.bookingUserInfo = this.bookingData.booking.user;
    this.bookingTime = this.bookingData.bookingTime;
    this.bookingStatus = this.bookingData.booking.status;
    this.bookingLocation = this.bookingData.booking.locationName;

    if (Object.prototype.toString.call(this.bookingData.booking.startDate) === "[object Date]") {
      console.log('IS A DATE')
      if (isNaN(this.bookingData.booking.startDate.getTime())) {
        console.log('INVALID DATE')
      } else {
        this.bookingDate = new Date(this.bookingData.booking.startDate.getTime());
        this.bookingData.booking.startDate = new Date(this.bookingData.booking.startDate.getTime());
        this.bookingData.booking.endDate = new Date(this.bookingData.booking.endDate.getTime());
      }
    } else {
      this.bookingDate = this.bookingData.booking.startDate.toDate();
      this.bookingData.booking.startDate = this.bookingData.booking.startDate.toDate();
      this.bookingData.booking.endDate = this.bookingData.booking.endDate.toDate();
    }

    // BOOKING TYPE
    if (this.bookingType !== 'userBookings') {
      this.setForm(current);
      this.notificationType = 'location';
    } else {
      this.bookingEditForm.removeControl('date');
      this.notificationType = 'user';
    }
    this.isLoading = false;
  }

  buildForm() {
    this.bookingEditForm = this.fb.group({
      date: [''],
    });
  }

  dateChange() {
    if (this.bookingData.booking.status === 3) {
      this.showActiveMessage = true;
    }
    this.showMessage = true;
    this.messagePlaceholder = 'Reason for change...';
    this.bookingEditForm.addControl('message', new FormControl('', Validators.required));
  }

  setForm(current) {

    // SET DATE
    const startDate = this.datePipe.transform(this.bookingData.booking.startDate, 'yyyy-MM-dd');
    const dateYear = Number(this.datePipe.transform(startDate, 'yyyy'));
    const dateMonth = Number(this.datePipe.transform(startDate, 'MM'));
    const dateDay = Number(this.datePipe.transform(startDate, 'dd'));

    this.bookingEditForm.controls.date.setValue({
      year: dateYear,
      month: dateMonth,
      day: dateDay
    });

    if (new Date(this.bookingData.booking.endDate) < new Date(current)) {
      this.removeUpdate = true;
      this.bookingEditForm.disable();
    }

    this.bookingEditForm.markAsUntouched();
    this.bookingEditForm.markAsPristine();
  }

  getStatus(status) {
    switch (status) {
      case 1:
        return `<span class="badge badge-warning">Pending</span>`;
        break;
      case 2:
        return `<span class="badge badge-success">Confirmed</span>`;
        break;
      case 3:
        return `<span class="badge badge-danger">Cancelled</span>`;
        break;
      default:
    }
  }

  onUpdate() {
    this.isSubmitting = true;
    const times = {
      open: {
        hour: +moment(this.bookingData.booking.startDate).format('HH'),
        minute: +moment(this.bookingData.booking.startDate).format('mm'),
        second: +moment(this.bookingData.booking.startDate).format('ss')
      },
      close: {
        hour: +moment(this.bookingData.booking.endDate).format('HH'),
        minute: +moment(this.bookingData.booking.endDate).format('mm'),
        second: +moment(this.bookingData.booking.endDate).format('ss')
      }
    }

    const selectedDate = this.bookingEditForm.get('date').value;

    this.bookingData.booking.startDate = new Date(selectedDate.year, selectedDate.month - 1, selectedDate.day, times.open.hour, times.open.minute, times.open.second);
    this.bookingData.booking.endDate = new Date(selectedDate.year, selectedDate.month - 1, selectedDate.day, times.close.hour, times.close.minute, times.close.second);
    if (this.showActiveMessage) this.bookingData.booking.status = 2;
    let pendingData = {
      startDate: this.bookingData.booking.startDate,
      locationRef: this.bookingData.booking.locationRef,
      oldLocation: this.bookingLocation,
      oldDate: this.bookingDate,
      oldStatus: this.bookingStatus,
      status: this.bookingData.booking.status,
      createdBy: this.bookingData.booking.createdBy,
      user: this.bookingData.booking.user
    };
    this.bookingsService.updateBooking(this.bookingData.booking, this.bookingData.booking.uid).then(() => {
      this.bookingsService.sendPendingNotify(pendingData, this.bookingEditForm.get('message').value, this.notificationType).then(() => {
        this.isSubmitting = false;
        this.bookingEditForm.markAsPristine();
        this.toastr.success('Booking updated');
        this.closeModal.emit(true);
      }).catch(error => {
        this.toastr.error('Error updating booking : ', error);
      });
    }).catch(error => {
      this.toastr.error('Error updating booking : ', error);
    });
  }

  private pad(i: number): string {
      return i < 10 ? `0${i}` : `${i}`;
  }

  onDelete(type, remove?) {
    if (type === 'warning') {
      this.showDeleteButtons = true;
      if (this.bookingTime === 'future' && !remove) {
        this.showMessage = true;
        this.messagePlaceholder = 'Reason for cancellation...';
        this.bookingEditForm.addControl('message', new FormControl('', Validators.required));
      }
    }
    if (type === 'delete') {
      let toastrMsg = '';
      if (this.bookingTime === 'future' && !remove) {
        this.bookingData.booking.status = 3;
        toastrMsg = 'Booking cancelled';
        this.sendNotification = true;
      }
      else {
        this.bookingData.booking.active = false;
        toastrMsg = 'Booking removed';
        this.sendNotification = false;
      }

      this.bookingsService.updateBooking(this.bookingData.booking, this.bookingData.booking.uid).then(() => {
        if (this.sendNotification) {
          this.bookingsService.sendPendingNotify(this.bookingData.booking, this.bookingEditForm.get('message').value, this.notificationType).then(() => {
            this.toastr.success(toastrMsg);
            this.showDeleteButtons = false;
            this.showMessage = false;
            this.messagePlaceholder = '';
            this.closeModal.emit(true);
          });
        } else {
          this.toastr.success(toastrMsg);
          this.showDeleteButtons = false;
          this.showMessage = false;
          this.messagePlaceholder = '';
          this.closeModal.emit(true);
        }
      }).catch(error => {
        this.toastr.error('Error deleting booking : ', error);
      })
    }
    if (type === 'cancel') {
      this.showDeleteButtons = false;
      this.showMessage = false;
      this.messagePlaceholder = '';
      if (this.bookingType !== 'userBookings') {
        const current = new Date();
        this.setForm(current);
      }
      if (this.bookingEditForm.get('message')) {
        this.bookingEditForm.removeControl('message');
      }
    }
  }

  ngOnDestroy() {
  }

}
