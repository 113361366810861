import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Location, DatePipe } from '@angular/common';
import { Subject, Subscription, Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/auth/_services';
import { StorageService } from 'src/app/_shared/_services/storage.service';
import { PerfectScrollbarConfigInterface, PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { TransactionsService } from 'src/app/_shared/_services/transactions.service';

@Component({
  selector: 'app-checkins-list',
  templateUrl: './checkIns-list.html',
  styleUrls: ['../scrollable-lists.scss']
})
export class CheckInsListComponent implements OnInit, OnDestroy {

    @Input() checkIns$: Observable<Array<{}>>;
    @Input() viewMember: boolean;
    @Input() type: string = '';
    @Output() sendCheckIn = new EventEmitter();

    isLoading: boolean = false;

    checkInsSubscription: Subscription;
    checkIns: any[];
    allCheckIns: any[];

    public config: PerfectScrollbarConfigInterface = {};
    @ViewChild(PerfectScrollbarComponent, { static: false }) componentRef?: PerfectScrollbarComponent;

    searchCriteria: any;
    showResetFilter: boolean = false;

    isCancelling: boolean = false;

    constructor(
        private datePipe: DatePipe,
    ) { }

    ngOnInit() {
      this.isLoading = true;
      console.log(this.type)
      this.checkInsSubscription = this.checkIns$.subscribe(checkInsData => {
          this.checkIns = checkInsData;
          this.allCheckIns = checkInsData;
          this.isLoading = false;
      });
    }

    findCheckIns() {
      if (this.searchCriteria && this.searchCriteria !== ' ') {
        const val = this.searchCriteria.toLowerCase();
        const temp = this.allCheckIns.filter((checkIn) => {
            const created = this.datePipe.transform(checkIn.created.toDate(), 'dd MMMM yyyy');
            const userRef = checkIn.createdByRef.toLowerCase().indexOf(val) !== -1 || !val;
            const locationName = checkIn.locationName.toLowerCase().indexOf(val) !== -1 || !val;
            const locationRef = checkIn.locationRef.indexOf(val) !== -1 || !val;
            const date = created.toLowerCase().indexOf(val) !== -1 || !val;
            return userRef || locationName || locationRef || date;
        });
        this.checkIns = temp;
        this.showResetFilter = true;
      } else {
        this.checkIns = this.allCheckIns;
        this.showResetFilter = false;
      }
    }

    resetFilter() {
      this.searchCriteria = '';
      this.checkIns = this.allCheckIns;
      this.showResetFilter = false;
    }

    viewUser(userRef, userId, index) {
        const dataToSend = {
            type: 'viewUser',
            data: {
                userId,
                userRef,
                type: this.type,
                userIndex: index
            }
        }
        this.sendCheckIn.emit(dataToSend);
    }

    deleteCheckIn(checkIn) {
      this.isCancelling = true;
      const dataToSend = {
          type: 'deleteCheckIn',
          data: checkIn
      }
      this.sendCheckIn.emit(dataToSend);
      this.isCancelling = false;
    }

    ngOnDestroy(): void {
        this.checkInsSubscription.unsubscribe();
    }

}
