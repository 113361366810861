import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WSProductSelectComponent } from './ws-product-select/ws-product-select.component';
import { WSProductPaymentStepsComponent } from './ws-product-payment-steps/ws-product-payment-steps.component';
import { WSSubscriptionViewComponent } from './ws-subscription-view/ws-subscription-view.component';
import { WSPackageSelectComponent } from './ws-package-select/ws-package-select.component';

@NgModule({
    declarations: [
      WSProductSelectComponent,
      WSPackageSelectComponent,
      WSProductPaymentStepsComponent,
      WSSubscriptionViewComponent
    ],
    imports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule
    ],
    exports: [
      WSProductSelectComponent,
      WSPackageSelectComponent,
      WSProductPaymentStepsComponent,
      WSSubscriptionViewComponent
    ],
    providers: []
})
export class WSProductsModule {
}
