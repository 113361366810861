import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject, Subscription, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/auth/_services';
import { User } from '../../pages/wealth-spaces/users/_models/user.model';
import { LocationsService } from '../../pages/wealth-spaces/locations/_services/locations.service';
import { StorageService } from 'src/app/_shared/_services/storage.service';
import { NotificationsService } from 'src/app/_shared/_services/notifications.service';

@Component({
  selector: 'app-sticky-nav',
  templateUrl: './sticky-nav.component.html',
  styleUrls: ['./sticky-nav.component.scss']
})
export class StickyNavComponent implements OnInit {

  // USER
  user: User;
  userSubscription: Subscription;
  userFirstname: string;

  // LOCATIONS
  locationsSubscription: Subscription;
  locations: any[];
  public model: any;

  // NOTIFICATIONS
  notificationsSubscription: Subscription;
  notifications$: Observable<Array<{}>>;
  notificationsCount: 0;

  constructor(
    public router: Router,
    public auth: AuthenticationService,
    private locationService: LocationsService,
    private storageService: StorageService,
    private notificationsService: NotificationsService
  ) { }

  ngOnInit() {
    this.userSubscription = this.auth.user.subscribe(userDetails => {
      if (userDetails) {
        this.user = userDetails;
        this.userFirstname = this.user.firstname.split(' ')[0];
        this.locationsSubscription = this.locationService.getPublicLocations().subscribe(locationsData => {
          this.locations = locationsData;
        });
        this.notifications$ = this.notificationsService.getUserNotifications(this.user.uid);
      }
    });
  }

  logout() {
    this.auth.signOut();
    this.user = null;
  }

  // SEARCH USERS
  searchLocations = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 ? []
        : this.locations.filter(v =>
          (v.name.toLowerCase().indexOf(term.toLowerCase()) > -1) ||
          (v.address.formatted_address.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10))
    )

  formatter = (x: { name: string }) => x.name;

  onSelect($event) {
    setTimeout(() => {
      this.model = '';
    }, 0);
    this.router.navigate([`/location/${$event.item.slug}`]);
    sessionStorage.setItem("locationId", $event.item.uid);
  }

  getNotificationsLength(event) {
    this.notificationsCount = event;
  }

}
