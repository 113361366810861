import { Component, OnInit, Input } from '@angular/core';
import { Location, DatePipe } from '@angular/common';
import { StorageService } from 'src/app/_shared/_services/storage.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbDate, NgbCalendar, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BookingsService } from '../../wealthspaces/ws-bookings/_services/bookings.service';
import { AuthenticationService } from 'src/app/auth/_services';
import { config } from 'src/app/_shared/_configs/config';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { TransactionsService } from 'src/app/_shared/_services/transactions.service';

declare var $: any;

@Component({
  selector: 'app-modal-booking-room',
  templateUrl: './modal-booking-room.html',
  styleUrls: ['./modal-booking-room.scss']
})
export class ModalBookingRoomComponent implements OnInit {

  bookRoomForm: FormGroup;
  booking: any;

  @Input() modalData: any;

  hoveredDate: NgbDate;
  meetingDate: NgbDate;
  minDate = undefined;

  formDetails;
  paymentDetails;

  submitting = false;
  showBookingDetails;

  meetingRoom: any;
  locationId: string;
  meetingRoomBookings: Subscription;
  allBookingTimes: any[] = [];
  isLoading: boolean = false;
  startTime: { hour: 0, minute: 0 };

  showBookedMessage: boolean = false;

  // DATES
  disabledDays: any[] = [];
  businessHours: any;
  currentDayTime: any;

  // CARDS
  cardForm: FormGroup;
  userCardsSubscription: Subscription;
  userCards: any[];
  changeDefault: boolean = false;
  changedCardId: string;

  isRedirecting: boolean = false;

  constructor(
    public storageService: StorageService,
    private fb: FormBuilder,
    public calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    private toastr: ToastrService,
    private bookingsService: BookingsService,
    private auth: AuthenticationService,
    private datePipe: DatePipe,
    private transactionsService: TransactionsService
  ) {
  }

  ngOnInit() {
    this.isLoading = true;
    $("#bookingRoomModal").modal("show");
    // SET DATE RANGE
    const currentDate = new Date();
    this.minDate = {
      year: currentDate.getFullYear(),
      month: currentDate.getMonth() + 1,
      day: currentDate.getDate()
    };
    this.meetingRoom = this.modalData.data.room;

    this.businessHours = this.modalData.data.businessHours;
    this.currentDayTime = this.modalData.data.currentDayTime;


    this.markDisabledDays(this.businessHours);
    console.log(this.modalData.data)
    this.buildForm();
    this.meetingRoomBookings = this.bookingsService.getMeetingRoomBookings(this.meetingRoom.uid).subscribe((bookingsData: any) => {
      bookingsData.forEach((booking) => {
        if (booking.start.getTime() >= currentDate.getTime()) {
          const times = {
            date: booking.start.getDate() + '/' + booking.start.getMonth() + '/' + booking.start.getYear(),
            start: booking.start.getTime(),
            end: booking.end.getTime()
          }
          this.allBookingTimes.push(times)
        }
      });
      this.isLoading = false;
    });


    // GET USER CARDS
    this.userCardsSubscription = this.transactionsService.getUserCards(this.auth.userDetails.uid).subscribe(cardsData => {
      this.userCards = cardsData;
      if (this.userCards.length > 0) {
        if (this.userCards.length === 1) {
          this.transactionsService.setDefaultCard(this.userCards[0].id).then(() => {
            this.setDefaultCard();
          });
        } else {
          this.setDefaultCard();
        }
      }
    })
  }

  buildForm() {
    this.bookRoomForm = this.fb.group({
      noPeople: ['', [Validators.required, Validators.max(this.meetingRoom.maxPeople), Validators.min(1)]],
      meetingDate: ['', Validators.required],
      meetingTime: this.fb.group({
        start: ['', [Validators.required]],
        end: ['', [Validators.required]]
      }),
      additionalInfo: ['']
    });

    this.bookRoomForm.get('meetingTime').disable();
    this.onValueChanges();

    this.cardForm = this.fb.group({
      card: [''],
      defaultCard: [''],
    });
  }

  markDisabledDays(businessHours) {
    businessHours.monday.day = 1;
    businessHours.tuesday.day = 2;
    businessHours.wednesday.day = 3;
    businessHours.thursday.day = 4;
    businessHours.friday.day = 5;
    businessHours.saturday.day = 6;
    businessHours.sunday.day = 7;
    let filtered = Object.values(businessHours);
    filtered.forEach((day: any) => {
      if (JSON.stringify(day.open) === JSON.stringify(day.close)) {
        this.disabledDays.push(day);
      }
    });
  }

  markDisabled = (date: NgbDate)=> {
    return this.disabledDays.find(x=>this.calendar.getWeekday(date) === x.day);
  }

  setDefaultCard() {
    const defaultCard = this.userCards.filter((card) => {
      return card.default == true;
    });
    if (defaultCard[0]) {
      this.cardForm.get('card').patchValue(defaultCard[0].id);
    }
  }

  onCardChange() {
    this.changedCardId = this.cardForm.get('card').value;
    if (this.changedCardId) {
      const noChange = this.userCards.some(card => card.id === this.changedCardId && card.default === true);
      if (!noChange) {
        this.changeDefault = true;
        this.cardForm.get('defaultCard').reset();
      } else {
        this.changeDefault = false;
      }
    }
  }

  setCardAsDefault() {
    let setDefault = this.cardForm.get('defaultCard').value;
    if (setDefault) {
      this.transactionsService.setDefaultCard(this.changedCardId, this.auth.userDetails.uid).then(() => {
        this.toastr.success(`Default card changed`);
      });
    }
  }

  onValueChanges(): void {
    this.bookRoomForm.get('meetingDate').valueChanges.subscribe((meetingDate: any) => {
      if (meetingDate) {
        this.bookRoomForm.get('meetingTime').enable();
        this.bookRoomForm.get('meetingTime').valid;
      } else {
        this.bookRoomForm.get('meetingTime').reset();
        this.bookRoomForm.get('meetingTime').disable();
      }
    });
    this.bookRoomForm.get('meetingTime.start').valueChanges.subscribe((startTime: any) => {
      let endTime;
      if (startTime) {
        // GET START TIME
        const startMeetingDate: any = new Date(
          this.bookRoomForm.get('meetingDate').value.year,
          this.bookRoomForm.get('meetingDate').value.month - 1,
          this.bookRoomForm.get('meetingDate').value.day,
          parseInt(this.pad(startTime.hour)),
          parseInt(this.pad(startTime.minute)),
          parseInt(this.pad(0)));
        const startingTime = startMeetingDate.getTime();
        const date = startMeetingDate.getDate() + '/' + startMeetingDate.getMonth() + '/' + startMeetingDate.getYear();
        // GET ALL BOOKING TIMES
        const checkStart = this.allBookingTimes.some(time => date === time.date && time.start <= startingTime && time.end > startingTime);
        if (!checkStart) {
          endTime = {
            hour: startTime.hour + 1,
            minute: startTime.minute
          };
          this.bookRoomForm.get('meetingTime.end').patchValue(endTime);
          this.showBookedMessage = false;
        } else {
          this.bookRoomForm.get('meetingTime.end').reset();
          this.showBookedMessage = true;
        }
      }
    });
    this.bookRoomForm.get('meetingTime.end').valueChanges.subscribe((endTime: any) => {
      if (endTime) {
        // GET END TIME
        const endMeetingDate: any = new Date(
          this.bookRoomForm.get('meetingDate').value.year,
          this.bookRoomForm.get('meetingDate').value.month - 1,
          this.bookRoomForm.get('meetingDate').value.day,
          parseInt(this.pad(endTime.hour)),
          parseInt(this.pad(endTime.minute)),
          parseInt(this.pad(0)));
        const endingTime = endMeetingDate.getTime();
        const date = endMeetingDate.getDate() + '/' + endMeetingDate.getMonth() + '/' + endMeetingDate.getYear();
        // GET ALL BOOKING TIMES
        const checkEnd = this.allBookingTimes.some(time => date === time.date && time.start < endingTime);
        if (checkEnd) {
          this.bookRoomForm.get('meetingTime.end').reset();
          this.showBookedMessage = true;
        } else {
          this.showBookedMessage = false;
        }
      }
    });
  }

  onProceed() {
    console.log("ModalBookingRoomComponent -> onProceed -> this.modalData.data HERE->", this.modalData.data)
    this.showBookingDetails = true;
    this.formDetails = this.bookRoomForm.value;
    const startMeetingDate: any = new Date(
      this.formDetails.meetingDate.year,
      this.formDetails.meetingDate.month - 1,
      this.formDetails.meetingDate.day,
      parseInt(this.pad(this.formDetails.meetingTime.start.hour)),
      parseInt(this.pad(this.formDetails.meetingTime.start.minute)),
      parseInt(this.pad(0)));

    const endMeetingDate: any = new Date(
      this.formDetails.meetingDate.year,
      this.formDetails.meetingDate.month - 1,
      this.formDetails.meetingDate.day,
      parseInt(this.pad(this.formDetails.meetingTime.end.hour)),
      parseInt(this.pad(this.formDetails.meetingTime.end.minute)),
      parseInt(this.pad(0)));

    const milliseconds = Math.abs(startMeetingDate - endMeetingDate);
    const hours = milliseconds / 36e5;
    const calculatedPrice = (hours * this.modalData.data.room.hourlyRate);

    this.booking = {
      active: true,
      price: calculatedPrice,
      created: new Date(),
      createdBy: this.auth.userId,
      createdByName: `${this.auth.userDetails.firstname} ${this.auth.userDetails.surname}`,
      createdByMail: this.auth.userDetails.email,
      name: this.modalData.data.room.name,
      startDate: startMeetingDate,
      endDate: endMeetingDate,
      locationId: this.modalData.data.room.locationId,
      locationRef: `entities/${config.entityId}/locations/${this.modalData.data.room.locationId}`,
      locationName: this.modalData.data.room.locationName,
      type: 'meetingRoom',
      typeId: this.meetingRoom.uid,
      typeRef: `entities/${config.entityId}/meetingRooms/${this.meetingRoom.uid}`,
      referencePrefix: `MEETING-B-`,
      entityId: config.entityId,
      source: config.source,
      description: this.formDetails.additionalInfo,
      noPeople: this.formDetails.noPeople,
      address: this.meetingRoom.address,
    }
  }

  makePayment() {
    let paymentUrl = 'https://secret-island-96823.herokuapp.com/';
    let paymentUrlParams;
    let cardId;
    let card;
    if (this.cardForm.get('card').value && this.userCards.length > 0) {
      cardId = `&cardId=${this.cardForm.get('card').value}`;
      card = `&card=No`;
    } else {
      cardId = '';
      card = `&card=Yes`;
    }
    let onceOff = true;
    this.bookingsService.createUserMeetingBooking(this.booking).then(bookingId => {
      this.transactionsService.addTransaction(this.booking, this.booking.price, onceOff, bookingId).then(transactionId => {
        if (transactionId) {
          this.isRedirecting = true;
          paymentUrlParams = `${paymentUrl}?amount=${this.booking.price}&returnUrl=${environment.websiteUrl}payments/confirmation?transactionId=${transactionId}&userId=${this.auth.userDetails.uid}&transactionId=${transactionId}${card}${cardId}`;
          window.location.href = paymentUrlParams;
        }
      });
    })
  }

  private pad(i: number): string {
      return i < 10 ? `0${i}` : `${i}`;
  }

  ngOnDestroy() {
  }

  onModalHide() {
    $('#bookingRoomModal').on("hidden.bs.modal", () => {
      this.destroyModal();
    });
  }

  hideModal() {
    $('#bookingRoomModal').modal('hide');
  }

  closeModal(value) {
    if (value) {
      this.hideModal();
    }
  }

  destroyModal() {
    const modalInfo = {};
    this.storageService.openModal(modalInfo);
  }

}
