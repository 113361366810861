import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subject, Subscription, Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/auth/_services';
import { StorageService } from 'src/app/_shared/_services/storage.service';
import { PerfectScrollbarConfigInterface, PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { NotificationsService } from 'src/app/_shared/_services/notifications.service';

@Component({
  selector: 'app-nav-notifications-list',
  templateUrl: './nav-notifications-list.html',
  styleUrls: ['../scrollable-lists.scss']
})
export class NavNotificationsListComponent implements OnInit, OnDestroy {

    @Input() notifications$: Observable<Array<{}>>;
    @Output() sendNotificationCount = new EventEmitter();

    notificationsSubscription: Subscription;
    notifications: any[];
    
    public config: PerfectScrollbarConfigInterface = {};
    @ViewChild(PerfectScrollbarComponent, { static: false }) componentRef?: PerfectScrollbarComponent;

    constructor(
        private notificationsService: NotificationsService
    ) { }

    ngOnInit() {
        this.notificationsSubscription = this.notifications$.subscribe(notificationsData => {
            this.notifications = notificationsData;
            this.sendNotificationCount.emit(this.notifications.length)
        });
    }

    markAsRead(notificationId) {
        const data = {
            unread: false
        }
        this.notificationsService.updateNotifiction(notificationId, data);
    }

    ngOnDestroy(): void {
        this.notificationsSubscription.unsubscribe();
    }

}
