import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl, ValidatorFn, FormArray, NgForm, FormControl } from '@angular/forms';
import { EventsService } from '../../../../../theme/pages/wealth-spaces/events/_services/events.service';
import { User } from '../../../../../theme/pages/wealth-spaces/users/_models/user.model';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';

@Component({
  selector: 'ws-events-invite',
  templateUrl: './ws-events-invite.component.html'
})
export class WSEventsInviteComponent implements OnInit, OnDestroy {

  @Input() extraData: any;
  @Output() closeModal = new EventEmitter();

  inviteForm: FormGroup;

  event: any;
  user: User;

  isSubmitting: boolean = false;

  constructor(
    private fb: FormBuilder,
    private eventsService: EventsService,
    private toastr: ToastrService
  ) {
  }

  ngOnInit() {
    this.event = this.extraData.data.event;
    this.user = this.extraData.data.user;
    this.buildForm();
  }

  buildForm() {
    this.inviteForm = this.fb.group({
      inviteFullname: ['', Validators.required],
      inviteEmail: ['', [Validators.required, Validators.email]],
      eventName: [this.event.title, Validators.required],
      eventDate: [moment(this.event.startDate.toDate()).format('DD MMMM YYYY'), Validators.required],
      eventTimeStart: [moment(this.event.startDate.toDate()).format('HH:mm A'), Validators.required],
      eventTimeEnd: [moment(this.event.endDate.toDate()).format('HH:mm A'), Validators.required],
      eventLocation: [this.event.locationName, Validators.required],
      eventUrl: [`${environment.clientUrl}event/${this.event.refNo}`, Validators.required],
      eventRef: [this.event.refNo, Validators.required],
    });
  }

  onSubmit() {
    this.isSubmitting = true;
    console.log(this.inviteForm.value)
    this.eventsService.sendEventInvite(this.inviteForm.value, this.user).then(() => {
      this.toastr.success('Invite sent');
      this.isSubmitting = false;
      this.closeModal.emit(true);
    }).catch(error => {
      this.isSubmitting = false;
      this.toastr.error('ERROR: ', error);
    });
  }

  ngOnDestroy() {

  }

}
