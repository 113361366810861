import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../_services';

@Component({
  selector: 'app-social',
  templateUrl: './auth-social.component.html'
})
export class AuthSocialComponent implements OnInit, OnDestroy {

  @Input() refCode: string;

  authSubscription: Subscription;
  errorSubscription: Subscription;
  errorMessage: any;

  constructor(
    public auth: AuthenticationService,
    private router: Router
  ) { }

  ngOnInit() {
      // IF USER ALREADY LOGGED IN REDIRECT TO ENTITIES
      this.authSubscription = this.auth.user.subscribe(user => {
          if (user) {
              this.router.navigate([''])
          }
      });
      this.errorSubscription = this.auth.authErrors.subscribe((error: any) => {
        if (Object.keys(error).length > 0) {
          this.errorMessage = error.message;
        } else {
          this.errorMessage = undefined;
        };
      });
  }

  signInWithGoogle() {
    let refCode;
    (this.refCode) ? refCode = this.refCode : refCode = '';
      this.auth.googleLogin(refCode)
          .then(() => {
              this.afterSignIn();
          }).catch((error) => {
            if (error) {
              this.auth.errorSubject.next(error);
            }
          });
  }


  signInWithFacebook() {
    let refCode;
    (this.refCode) ? refCode = this.refCode : refCode = '';
    this.auth.facebookLogin(refCode)
      .then(() => {
        this.afterSignIn();
      }).catch((error) => {
          if (error) {
            this.auth.errorSubject.next(error);
          }
        });
  }

  private afterSignIn() {
      this.authSubscription = this.auth.user.subscribe(user => {
          if (user) {
              this.auth.updateOnlineStatus(user.uid, true);
              this.router.navigate(['/dashboard']);
          }
      });
  }

  ngOnDestroy() {
      this.authSubscription.unsubscribe();
      this.errorSubscription.unsubscribe();
  }

}
