import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { WSBookingsEditEventFormComponent } from './ws-bookings-edit/events/bookings-edit-event.form';
import { WSBookingsEditRoomFormComponent } from './ws-bookings-edit/meeting-rooms/bookings-edit-room.form';
import { WSBookingsEditLocationFormComponent } from './ws-bookings-edit/locations/bookings-edit-location.form';

@NgModule({
  declarations: [
    WSBookingsEditEventFormComponent,
    WSBookingsEditRoomFormComponent,
    WSBookingsEditLocationFormComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule
  ],
  exports: [
    WSBookingsEditEventFormComponent,
    WSBookingsEditRoomFormComponent,
    WSBookingsEditLocationFormComponent
  ],
  providers: []
})
export class WsBookingsModule {
}
