import { Component, OnInit, Input } from '@angular/core';
import { ReactiveFormsModule, FormGroup, FormBuilder, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { AuthenticationService } from '../../_services';

type UserFields = 'email' | 'password' | 'firstname' | 'surname' | 'confirm_password' | 'contactNumber';
type FormErrors = { [u in UserFields]: string };

@Component({
  selector: 'app-register',
  templateUrl: './auth-register.component.html'
})
export class AuthRegisterComponent implements OnInit {

  @Input() refCode: string;

  userRegisterForm: FormGroup;
  errorMessage: any;
  errorCode: any;
  showVerifyButton: boolean = false;
  confirmationMessage: any;
  isSubmitting: boolean = false;
  uid: string;
  registerUserInfo: boolean = false;
  isLoading: boolean = true;

  userRegister: boolean = false;
  userInfo: any;
  authSubscription: Subscription;
  isAdmin: any;

  formErrors: FormErrors = {
    'firstname': '',
    'surname': '',
    'email': '',
    'contactNumber': '',
    'password': '',
    'confirm_password': '',
  };
  validationMessages = {
    'firstname': {
      'required': 'Please enter your Firstname.',
    },
    'surname': {
      'required': 'Please enter your Firstname.',
    },
    'email': {
      'required': 'Email is required.',
      'email': 'Email must be a valid email',
    },
    'contactNumber': {
      'required': 'Contact number is required.',
    },
    'password': {
      'required': 'Password is required.',
      'pattern': 'Password must include atleast one letter and one number.',
      'minlength': 'Password must be at least 6 characters long.',
      'maxlength': 'Password cannot be more than 25 characters long.',
    },
    'confirm_password': {
      'required': 'Confirm password is required',
      'passwordMismatch': 'Passwords do not match',
    },
    'checkbox': {
      'required': 'Please tick to indicate you have read and agreed with the terms and conditions.',
    },

  };

  constructor(
    private fb: FormBuilder,
    private auth: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.buildForm();
    this.authSubscription = this.auth.user.subscribe(userDetails => {
      if (userDetails) {
        console.log(userDetails);
        this.router.navigate(['']);
      } else {
        this.route.params.subscribe((params: Params) => {
          if (params['uid']) {
            const decodeUserBase64: any = JSON.parse(atob(params['uid']));

            // CHECK IF VALID USER DETAILS
            if (decodeUserBase64.uid) {
                // Set Router
                this.uid = decodeUserBase64.uid;
                this.isAdmin = decodeUserBase64.admin;

                this.auth.checkIfUserRegistered(this.uid)
                    .then(() => {
                        console.log('Register page');
                        this.userRegisterForm.patchValue(decodeUserBase64);
                        this.registerUserInfo = true;
                        this.userRegister = true;
                        this.isLoading = false;
                    }).catch(() => {
                        this.router.navigate(['/login']);
                    });
            } else {
                this.router.navigate(['/login']);
            }
          }
          if (this.refCode) {
            this.userRegisterForm.get('refCode').patchValue(this.refCode);
          }
        });
      }
    });
  }

  signup() {
    this.isSubmitting = true;
    this.errorMessage = undefined;
    let permissions;
    let refCode;
    (this.isAdmin) ? permissions = ['user', 'admin'] : permissions = ['user'];
    (this.refCode) ? refCode = this.refCode : refCode = '';
    this.auth.emailSignUp(this.userRegisterForm.value['email'], this.userRegisterForm.value['contactNumber'], this.userRegisterForm.value['password'], this.userRegisterForm.value['firstname'], this.userRegisterForm.value['surname'], permissions, refCode)
      .then(result => {
        // this.reset();
        this.isSubmitting = false;
        this.router.navigate(['/dashboard']);
      }).catch(error => {
        if (error) {
          console.log(error);
          this.isSubmitting = false;
          this.errorMessage = error.message;
        }
      });

  }

  signInWithGoogle() {
    this.auth.googleLogin()
      .then(() => {
        this.isSubmitting = true;
        this.router.navigate(['/dashboard']);
      });
  }

  buildForm() {
    this.userRegisterForm = this.fb.group({
      'firstname': ['', [
        Validators.required
      ]],
      'surname': ['', [
        Validators.required
      ]],
      'email': ['', [
        Validators.required,
        Validators.email
      ]],
      'contactNumber': ['', [
        Validators.required
      ]],
      'password': ['', [
        Validators.required,
        Validators.pattern("^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+).*$"),
        Validators.minLength(6),
        Validators.maxLength(25)

      ]],
      'confirm_password': ['', [
        Validators.required, this.isEqualTo('password')
      ]],
      'checkbox': ['', [
        Validators.required
      ]],
      'refCode': ['']
    });

    function checkCheckbox(c: AbstractControl) {
      if (c.get('checkbox').value == false) {
        return false;
      } else return true;
    }

    this.userRegisterForm.valueChanges.subscribe((data) => this.onValueChanged(data));
    this.onValueChanged();
  }

  isEqualTo(field): ValidatorFn {

    return (control: AbstractControl): { [key: string]: any } => {
      if (control.root.value[field] === control.value) {
        return null;
      }
      else {
        return { 'passwordMismatch': { value: 'passwordMismatch' } };
        //return null;
      }
    };
  }

  reset() {
    this.buildForm();
  }

  // Updates validation state on form changes.
  onValueChanged(data?: any) {
    if (!this.userRegisterForm) { return; }
    const form = this.userRegisterForm;
    for (const field in this.formErrors) {
      if (Object.prototype.hasOwnProperty.call(this.formErrors, field) && (field === 'email' || field === 'contactNumber' || field === 'password' || field === 'confirm_password' || field === 'firstname' || field === 'surname' || field === 'checkbox')) {
        // clear previous error message (if any)
        this.formErrors[field] = '';
        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          if (control.errors) {
            for (const key in control.errors) {
              if (Object.prototype.hasOwnProperty.call(control.errors, key)) {
                this.formErrors[field] += `<li>${(messages as { [key: string]: string })[key]}</li>`;
              }
            }
          }
        }
      }
    }
  }

}
