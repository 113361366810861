import { Component, OnInit, ViewChild, Input, Output, EventEmitter, } from '@angular/core';
import { ReactiveFormsModule, FormGroup, FormBuilder, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../_services';

// declare var swal: any;

type UserFields = 'email' | 'password';
type FormErrors = { [u in UserFields]: string };

@Component({
  selector: 'app-login',
  templateUrl: './auth-login.component.html'
})
export class AuthLoginComponent implements OnInit {

  userLoginForm: FormGroup;
  newUser = false; // to toggle login or signup form
  displayOptions = false;
  failedLogin: boolean = false;

  formErrors: FormErrors = {
    email: '',
    password: ''
  };
  validationMessages = {
    email: {
      required: 'Email is required.',
      email: 'Email must be a valid email',
    },
    password: {
      required: 'Password is required.',
      pattern: 'Password must include atleast one letter and one number.',
      minlength: 'Password must be at least 6 characters long.',
      maxlength: 'Password cannot be more than 25 characters long.'
    }
  };

  isLoading: boolean = false;

  constructor(
    private fb: FormBuilder,
    private auth: AuthenticationService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.buildForm();
  }

  login() {
    this.isLoading = true;
    this.auth.emailLogin(this.userLoginForm.value.email, this.userLoginForm.value.password)
      .catch((error) => {
        if (error) {
          this.isLoading = false;
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log('ERRORS', errorCode, errorMessage);
        }
      }).then((result) => {
        if (result === undefined) {
          this.failedLogin = true;
        } else {
          //this.dataService.modalBox = false;
          this.failedLogin = false;
          this.router.navigate(['']);
        }
      });
  }

  buildForm() {
    this.userLoginForm = this.fb.group({
      email: ['', [
        Validators.required,
        Validators.email
      ]],
      password: ['', [
        Validators.required,
      ]],
    });

    this.userLoginForm.valueChanges.subscribe((data) => this.onValuesChanged(data));
    this.onValuesChanged();

    function checkCheckbox(c: AbstractControl) {
      if (c.get('checkbox').value == false) {
        return false;
      } else return true;
    }
  }

  isEqualTo(field): ValidatorFn {

    return (control: AbstractControl): { [key: string]: any } => {
      if (control.root.value[field] === control.value) {
        return null;
      }
      else {
        return { passwordMismatch: { value: 'passwordMismatch' } };
        // return null;
      }
    };
  }

  // Updates validation state on form changes.
  onValuesChanged(data?: any) {
    if (!this.userLoginForm) { return; }
    const form = this.userLoginForm;
    for (const field in this.formErrors) {
      if (Object.prototype.hasOwnProperty.call(this.formErrors, field) && (field === 'email' || field === 'password')) {
        // clear previous error message (if any)
        this.formErrors[field] = '';
        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          if (control.errors) {
            for (const key in control.errors) {
              if (Object.prototype.hasOwnProperty.call(control.errors, key)) {
                this.formErrors[field] += `${(messages as { [key: string]: string })[key]} `;
              }
            }
          }
        }
      }
    }
  }

}
