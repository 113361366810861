import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { WSUsersViewComponent } from './ws-users-view/ws-users-view';
import { WSUsersSearchComponent } from './ws-users-search/ws-users-search';
import { WsRatingsModule } from '../ws-ratings/ws-ratings.module';

@NgModule({
  declarations: [
    WSUsersViewComponent,
    WSUsersSearchComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    WsRatingsModule,
    NgbModule
  ],
  exports: [
    WSUsersViewComponent,
    WSUsersSearchComponent
  ],
  providers: []
})
export class WsUsersModule {
}
