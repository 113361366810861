import { MessagesService } from './_services/messages.service';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';
import { AuthModule } from '../auth/auth.module';
import { AuthenticationService } from '../auth/_services';
import { UsersService } from '../theme/pages/wealth-spaces/users/_services/users.service';
import { EntitiesService } from './_services/entities.service';
import { FilesModule } from './_components/files/files.module';
import { FilesService } from './_components/files/files.service';
import { GenerateThumbnailService } from './_services/generate-thumbnail.service';
import { DataTablesModule } from 'angular-datatables';
import { AuditLogService } from './_services/audit-log.service';
import { ArchwizardModule } from 'angular-archwizard';
import { AgmCoreModule } from '@agm/core';
import { AgmOverlays } from "agm-overlays";
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TeamsService } from '../theme/pages/wealth-spaces/teams/_services/teams.service';
import { LocationsService } from '../theme/pages/wealth-spaces/locations/_services/locations.service';
import { MapsModule } from './_components/maps/maps.module';
import { SortablejsModule } from 'ngx-sortablejs';
import { UserSearchPipe } from './_pipes/users-search.pipe';
import { ToastrModule } from 'ngx-toastr';
import { NgPipesModule } from 'ngx-pipes';
import { MeetingRoomsService } from '../theme/pages/wealth-spaces/meeting-rooms/_services/meeting-rooms.service';
import { WealthSpacesCompModule } from './_components/wealthspaces/wealthspaces.module';
import { NouisliderModule } from 'ng2-nouislider';
import { ModalsModule } from './_components/modals/modals.module';
import { ProductsService } from './_services/products.service';
import { SubscriptionsService } from './_services/subscriptions.service';
import { EventsService } from '../theme/pages/wealth-spaces/events/_services/events.service';
import { BookingsService } from './_components/wealthspaces/ws-bookings/_services/bookings.service';
import { TransactionsService } from './_services/transactions.service';
import { RatingService } from './_services/ratings.service';
import { CalendarsModule } from './_components/calendars/calendars.module';
import { NotificationsService } from './_services/notifications.service';
import { ScrollableListsModule } from './_components/scrollable-lists/scrollable-lists.module';
import { QrcModule } from './_components/qr-code/qr-code.module';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [
    UserSearchPipe
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AuthModule,
    NgxPermissionsModule,
    RouterModule,
    FilesModule,
    DataTablesModule,
    ArchwizardModule,
    NouisliderModule,
    SortablejsModule.forRoot({ animation: 150 }),
    AgmOverlays,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDtzpkn_YCwOiDNLhP54Ye8tz1p352lW4k',
      libraries: ['places']
    }),
    NgbModule,
    MapsModule,
    ToastrModule.forRoot(),
    NgPipesModule,
    WealthSpacesCompModule,
    ModalsModule,
    CalendarsModule,
    ScrollableListsModule,
    QrcModule,
    HttpClientModule,
  ],
  exports: [
    AuthModule,
    NgxPermissionsModule,
    RouterModule,
    FilesModule,
    DataTablesModule,
    ArchwizardModule,
    NouisliderModule,
    SortablejsModule,
    AgmOverlays,
    AgmCoreModule,
    NgbModule,
    MapsModule,
    UserSearchPipe,
    ToastrModule,
    NgPipesModule,
    WealthSpacesCompModule,
    ModalsModule,
    CalendarsModule,
    ScrollableListsModule,
    QrcModule,
    HttpClientModule
  ],
  providers: [
    DatePipe
  ]
})
export class AppCoreModule {
  static forRoot() {
    return {
      ngModule: AppCoreModule,
      providers: [
        AuthenticationService,
        AuditLogService,
        NotificationsService,
        NgxPermissionsService,
        EntitiesService,
        UsersService,
        FilesService,
        GenerateThumbnailService,
        TeamsService,
        LocationsService,
        MeetingRoomsService,
        ProductsService,
        SubscriptionsService,
        EventsService,
        BookingsService,
        TransactionsService,
        RatingService,
        MessagesService
      ]
    };
  }
}
