import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject, Subscription, Observable } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { User } from 'src/app/theme/pages/wealth-spaces/users/_models/user.model';
import { StorageService } from 'src/app/_shared/_services/storage.service';

declare var $: any;

@Component({
    selector: 'ws-users-search',
    templateUrl: './ws-users-search.html',
    styleUrls: ['../ws-users.scss']
})
export class WSUsersSearchComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() users$: Observable<Array<{}>>;
  @Input() userType: string;
  @Input() locationId: string;

  users: User[];
  allUsers: User[];
  foundUsers: User[];
  userSubscription: Subscription;

  searchCriteria: any;
  showNotFound: boolean = false;
  showResetFilter: boolean = false;

  constructor(
    private storageService: StorageService
  ) {
  }

  ngOnInit() {
    this.userSubscription = this.users$.subscribe(usersData => {
      this.users = usersData;
      this.allUsers = usersData;
    });
  }

  findUsers() {
    if (this.searchCriteria && this.searchCriteria !== ' ' && this.searchCriteria.length > 2) {
      const val = this.searchCriteria.toLowerCase();
      const temp = this.allUsers.filter((user) => {
        const full_name = `${user.firstname} ${user.surname}`
        const name = full_name.toLowerCase().indexOf(val) !== -1 || !val;
        const email = user.email.toLowerCase().indexOf(val) !== -1 || !val;
        const contactNumber = user.contactNumber.indexOf(val) !== -1 || !val;
        const refNo = (user.refNo) ? user.refNo.toLowerCase().indexOf(val) !== -1 || !val : '';
        return name || email || contactNumber || refNo;
      });
      this.users = temp;
      this.foundUsers = temp;
      this.showResetFilter = true;
      this.showNotFound = (this.users.length === 0) ? true : false;
      this.showNotFound = (this.foundUsers.length === 0) ? true : false;
    } else {
      this.users = this.allUsers;
      this.foundUsers = [];
      this.showNotFound = true;
    }
  }

  resetFilter() {
    this.searchCriteria = '';
    this.users = this.allUsers;
    this.foundUsers = [];
    this.showNotFound = false;
    this.showResetFilter = false;
  }

  getUser(user, index) {
    const modalData = {
      modalId: 'usersModal',
      title: `${user.firstname} ${user.surname}`,
      type: `viewUser`,
      data: {
        userId: user.uid,
        type: '',
        locationId: this.locationId,
        userIndex: index
      }
    }
    this.storageService.openModal(modalData);
  }

  openChat(user: User) {
    const modalData = {
      modalId: 'userConversationModal',
      title: `Contact: ${user.firstname} ${user.surname}`,
      data: {
        userId: user.uid
      }
    }
    this.storageService.openModal(modalData);
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
  }

}
