import { Injectable } from '@angular/core';
import {
    AngularFirestore,
    AngularFirestoreCollection,
    AngularFirestoreDocument
} from '@angular/fire/firestore';
import { Observable, Subject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Audit } from '../_models/audit.model';
import { AuthenticationService } from '../../auth/_services/authentication.service';
import { User } from '../../theme/pages/wealth-spaces/users/_models/user.model';
import { QuerySnapshot } from '@firebase/firestore-types';
import { map } from 'rxjs/operators';

@Injectable()
export class AuditLogService {

  currentAudits: Audit[];
  currentUser: User;

  constructor(
    public auth: AuthenticationService,
    public afs: AngularFirestore,
    public router: Router
  ) {
      //FETCH LOGGED IN USER
      this.auth.user.subscribe(userDetails => {
          if (userDetails) {
              this.currentUser = userDetails;
          }
      });

  }

}
