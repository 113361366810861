import { Component, OnInit, OnDestroy, Input } from '@angular/core';

@Component({
  selector: 'app-modals',
  templateUrl: './modals.component.html'
})
export class ModalsComponent implements OnInit, OnDestroy {

  @Input() modalData: any;

  constructor(
  ) {

  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

}
