import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { Router } from "@angular/router";
import { config } from '../_configs/config';
import { combineLatest, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { FilesService } from 'src/app/_shared/_components/files/files.service';
import { AuthenticationService } from 'src/app/auth/_services';
import { UsersService } from 'src/app/theme/pages/wealth-spaces/users/_services/users.service';
import { NotificationsService } from './notifications.service';

@Injectable()
export class SubscriptionsService {

  entityId: string;

  authSubscription: Subscription;
  loggedInUser: any;

  constructor(
    public afs: AngularFirestore,
    public router: Router,
    private filesService: FilesService,
    private auth: AuthenticationService,
    private usersService: UsersService,
    private notificationsService: NotificationsService
  ) {
    this.entityId = config.entityId;
    this.authSubscription = this.auth.user.subscribe(userDetails => {
      if (userDetails) {
        this.loggedInUser = userDetails;
      }
    });
  }

  getUserSubscriptions(userId) {
    const subscriptionsCollection = this.afs.collection(`users/${userId}/entities/${this.entityId}/subscriptions`, ref => ref.orderBy('startDate', 'desc').where('active', '==', true).where('status', '==', 2));
    return subscriptionsCollection.valueChanges({ idField: 'id' });
  }

  getTeamSubscriptions(teamId) {
    const subscriptionsCollection = this.afs.collection(`entities/${this.entityId}/teams/${teamId}/subscriptions`, ref => ref.orderBy('startDate', 'desc').where('active', '==', true));
    return subscriptionsCollection.valueChanges({ idField: 'id' });
  }

  geSubscriptionDetails(uid) {
    const subscriptionsDoc = this.afs.doc(`entities/${this.entityId}/subscriptions/${uid}`);
    return subscriptionsDoc.valueChanges();
  }

  addUserPendingSubscription(subInfo) {

    let subscriptionsPendingDoc;
    let subscriptionsUsersDoc;
    let subData;

    if (subInfo.user) {
      subscriptionsPendingDoc = this.afs.doc(`pendingSubscriptions/${subInfo.user.uid}`);
      subData = {
        active: true,
        createdBy: subInfo.user.uid,
        createdByName: `${subInfo.user.firstname} ${subInfo.user.surname}`,
        created: new Date(),
        status: 1,
        userRef: subInfo.user.refNo,
        productRef: subInfo.product.refNo,
        product: subInfo.product.title,
        userId: subInfo.user.uid,
        productId: subInfo.product.uid,
        amount: subInfo.product.regularPrice,
        currency: 'ZAR',
        startDate: new Date(),
        frequency: Number(subInfo.renewalDay),
        billingInfo: subInfo.user.billingInfo,
        type: 'userSubscription'
      }
    }

    if (subInfo.team) {
      subscriptionsPendingDoc = this.afs.doc(`pendingSubscriptions/${subInfo.team.uid}`);
      subData = {
        active: true,
        createdBy: subInfo.payerInfo.uid,
        createdByName: `${subInfo.payerInfo.firstname} ${subInfo.payerInfo.surname}`,
        created: new Date(),
        status: 1,
        teamRef: subInfo.team.refNo,
        productRef: subInfo.product.refNo,
        product: subInfo.product.title,
        teamId: subInfo.team.uid,
        amount: subInfo.product.amount,
        currency: 'ZAR',
        startDate: new Date(),
        frequency: Number(subInfo.renewalDay),
        billingInfo: subInfo.team.billingInfo,
        type: 'teamSubscription'
      }
    }

    const pendingSubDoc = subscriptionsPendingDoc.set(subData, { merge: true });

    return Promise.all([pendingSubDoc]).then(() => {
      if (subInfo.teamUsers) {
        const userArray: any = [];
        subInfo.teamUsers.forEach((user: any, index, array) => {
          const product = (user.pendingProduct) ? user.pendingProduct : user.product;
          const userSubData = {
            active: true,
            amount: 0,
            product: product.product,
            productRef: product.productRef,
            productId: product.productId,
            frequency: Number(subInfo.renewalDay),
            startDate: new Date(),
            status: product.status,
            uid: subInfo.team.uid,
            teamName: subInfo.team.name,
            teamId: subInfo.team.uid,
            type: 'teamSubscription',
            source: config.source
          }
          subscriptionsUsersDoc = this.afs.doc(`users/${user.uid}/entities/${this.entityId}/subscriptions/t-${subInfo.team.uid}`);
          subscriptionsUsersDoc.set(userSubData, {merge: true});
          if (userArray.length === array.length) {
            console.log("User subscriptions added")
          }
        });
      }
    });
  }

  addConfirmedSubscription(typeId, status) {
    const subscriptionsPendingDoc = this.afs.doc(`pendingSubscriptions/${typeId}`);
    const subscriptionsDoc = this.afs.doc(`entities/${this.entityId}/subscriptions/${typeId}`);
    const subscriptionsUserDoc = this.afs.doc(`users/${typeId}/entities/${this.entityId}/subscriptions/${typeId}`);

    return subscriptionsPendingDoc.ref.get().then((subscription: any) => {
      if (subscription.exists) {
        const subData = subscription.data();
        subData.status = status;
        subData.source = config.source;
        subData.changeSub = false;

        const userSubData = {
          active: true,
          status: status,
          product: subData.product,
          productRef: subData.productRef,
          amount: subData.amount,
          productId: subData.productId,
          startDate: subData.startDate,
          frequency: subData.frequency,
          changeSub: subData.changeSub,
          subRef: `/entities/${this.entityId}/subscriptions/${typeId}`,
          uid: 'personal',
          type: subData.type
        }

        // NOTIFICATIONS
        const notification = {
          title: 'Personal Susbcription Purchase',
          body: `You have purchased the ${subData.product} Tier for R ${subData.amount}`,
          type: subData.type,
          createdBy: typeId,
          addToHistory: true,
          userId: typeId,
          customData: {
            createdBy: typeId,
            notificationUrl: `/profile`
          },
          historyInfo: {
            created: new Date(),
            title: 'Personal Susbcription Purchase',
            message: `You have purchased the '<strong>${subData.product}</strong>' Tier for <strong>R ${subData.amount}</strong>`,
            linkText: `View Profile`,
            type: subData.type,
            createdBy: typeId,
            userId: typeId,
            url: `/profile`,
            listRef: '/my-notifications',
            source: config.source,
            unread: true
          }
        }

        const sendToUser = {
          uid: typeId
        }

        const subDoc = subscriptionsDoc.set(subData, { merge: true });
        const userSubDoc = subscriptionsUserDoc.set(userSubData, { merge: true });
        const sendUserNotification = this.notificationsService.addNotification(notification, sendToUser);
        const deletePendingDoc = subscriptionsPendingDoc.delete();

        return Promise.all([subDoc, userSubDoc, sendUserNotification, deletePendingDoc]).then(() => {
          return userSubData;
        });
      }
    })
  }

  addConfirmedTeamSubscription(transaction) {
    const subscriptionsPendingDoc = this.afs.doc(`pendingSubscriptions/${transaction.typeId}`);
    const subscriptionsDoc = this.afs.doc(`entities/${this.entityId}/subscriptions/${transaction.typeId}`);
    const subscriptionsTeamDoc = this.afs.doc(`entities/${this.entityId}/teams/${transaction.typeId}/subscriptions/${transaction.typeId}`);
    const subscriptionsUserDoc = this.afs.doc(`users/${transaction.createdBy}/entities/${this.entityId}/subscriptions/${transaction.typeId}`);

    return subscriptionsPendingDoc.ref.get().then((subscription: any) => {
      if (subscription.exists) {

        const subData = subscription.data();
        subData.status = transaction.status;
        subData.source = config.source;
        subData.changeSub = false;

        const userSubData = {
          active: true,
          status: transaction.status,
          product: subData.product,
          productRef: subData.productRef,
          amount: subData.amount,
          startDate: subData.startDate,
          frequency: subData.frequency,
          changeSub: subData.changeSub,
          subRef: `/entities/${this.entityId}/subscriptions/${transaction.typeId}`,
          uid: transaction.typeId,
          type: subData.type
        }

        let loading = (transaction.status === 2) ? true : false;

        const teamSubData = {
          active: true,
          status: transaction.status,
          product: subData.product,
          productRef: subData.productRef,
          amount: subData.amount,
          startDate: subData.startDate,
          frequency: subData.frequency,
          changeSub: subData.changeSub,
          subRef: `/entities/${this.entityId}/subscriptions/${transaction.typeId}`,
          uid: transaction.typeId,
          type: subData.type,
          loadingSub: loading
        }

        // NOTIFICATIONS
        const notification = {
          title: 'Team Susbcription Purchase',
          body: `You have purchased the ${subData.product} Package for R ${subData.amount}`,
          type: subData.type,
          createdBy: transaction.createdBy,
          addToHistory: true,
          userId: transaction.createdBy,
          customData: {
            createdBy: transaction.createdBy,
            notificationUrl: `/teams/edit/${transaction.typeId}`
          },
          historyInfo: {
            created: new Date(),
            title: 'Team Susbcription Purchase',
            message: `You have purchased the '<strong>${subData.product}</strong>' Package for <strong>R ${subData.amount}</strong>`,
            linkText: `View Team`,
            type: subData.type,
            createdBy: transaction.createdBy,
            userId: transaction.createdBy,
            url: `/teams/edit/${transaction.typeId}`,
            listRef: '/my-notifications',
            source: config.source,
            unread: true
          }
        }

        const sendToUser = {
          uid: transaction.createdBy
        }

        const teamSubDoc = subscriptionsTeamDoc.set(teamSubData, { merge: true });
        const userSubDoc = subscriptionsUserDoc.set(userSubData, { merge: true });
        const subDoc = subscriptionsDoc.set(subData, { merge: true });
        const sendUserNotification = this.notificationsService.addNotification(notification, sendToUser);
        const deletePendingDoc = subscriptionsPendingDoc.delete();

        return Promise.all([subDoc, teamSubDoc, userSubDoc, sendUserNotification, deletePendingDoc]).then(() => {
          return userSubData;
        });
      }
    })
  }

  updateSubscription(subscriptionId, values) {
    const subscriptionsDoc = this.afs.doc(`entities/${this.entityId}/subscriptions/${subscriptionId}`);
    return subscriptionsDoc.set(values, {merge:true});
  }

  deleteUserTeamSubscription(userId, teamId) {
    const subscriptionsUserColl = this.afs.collection(`users/${userId}/entities/${this.entityId}/subscriptions`);
    return subscriptionsUserColl.ref.where('teamId', '==', teamId).get().then(subscriptions => {
      if (subscriptions.size > 0) {
        subscriptions.forEach(subscription => {
          subscription.ref.delete();
        });
      }
    });
  }

  deletePendingSubscription(typeId) {
    const subscriptionsPendingDoc = this.afs.doc(`pendingSubscriptions/${typeId}`);
    return subscriptionsPendingDoc.delete();
  }

}
