import { Route, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

export const appRoutes: Route[] = [
    {
        path: '', loadChildren: './auth/auth.module#AuthModule',
        runGuardsAndResolvers: 'always',
    },
];
export const AppRoutingModule: ModuleWithProviders = RouterModule.forRoot(appRoutes, { onSameUrlNavigation: 'reload' });
