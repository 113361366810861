import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { environment } from '../../../../environments/environment';
import { MapsSearchComponent } from './maps-search/maps-search.component';
import { AgmCoreModule } from '@agm/core';
import { AgmJsMarkerClustererModule, ClusterManager } from '@agm/js-marker-clusterer';
import 'js-marker-clusterer/src/markerclusterer.js';
import { FormsModule } from '@angular/forms';
import { MapsLocationsComponent } from './maps-locations/maps-locations.component';
import { NgPipesModule } from 'ngx-pipes';
import { MapsSingleLocationComponent } from './maps-single-location/maps-single-location.component';

@NgModule({
    imports: [
      CommonModule,
      RouterModule,
      AngularFireModule.initializeApp(environment.firebase),
      AngularFirestoreModule,
      AngularFireStorageModule,
      FormsModule,
      AgmCoreModule.forRoot({
        apiKey: 'AIzaSyDtzpkn_YCwOiDNLhP54Ye8tz1p352lW4k',
        libraries: ['places']
      }),
      AgmJsMarkerClustererModule,
      NgPipesModule
    ],
    declarations: [
      MapsSearchComponent,
      MapsLocationsComponent,
      MapsSingleLocationComponent
    ],
    providers: [ClusterManager],
    exports: [
      MapsSearchComponent,
      MapsLocationsComponent,
      MapsSingleLocationComponent
    ]
})
export class MapsModule { }
