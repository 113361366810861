import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { EventsService } from '../../../../../theme/pages/wealth-spaces/events/_services/events.service';
import { User } from '../../../../../theme/pages/wealth-spaces/users/_models/user.model';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'ws-events-rsvp',
  templateUrl: './ws-events-rsvp.component.html'
})
export class WSEventsRsvpComponent implements OnInit, OnDestroy {

  @Input() extraData: any;

  @Output() closeModal = new EventEmitter();

  event: any;
  user: User;

  isSubmitting: boolean = false;

  constructor(
    private eventsService: EventsService,
    private toastr: ToastrService
  ) {
  }

  ngOnInit() {
    this.event = this.extraData.data.event;
    this.user = this.extraData.data.user;
  }

  // TIMES
  toTime(time: any): string {
    if (!time) {
      return null;
    }
    let meridian;
    if (time.hour > 11) {
      meridian = 'PM'
    } else {
      meridian = 'AM'
    }
    return `${this.pad(time.hour)}:${this.pad(time.minute)} ${meridian}`;
  }

  private pad(i: number): string {
    return i < 10 ? `0${i}` : `${i}`;
  }

  onConfirm() {
    this.isSubmitting = true;
    this.eventsService.rsvpToEvent(this.event, this.user).then(() => {
      this.toastr.success('RSVP susccessfull');
      this.isSubmitting = false;
      this.closeModal.emit(true);
    }).catch(error => {
      this.isSubmitting = false;
      this.toastr.error('ERROR: ', error);
    });
  }

  ngOnDestroy() {

  }

}
