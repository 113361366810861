import { Injectable } from "@angular/core";
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from "@angular/fire/firestore";
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, Subject, Subscription, combineLatest, BehaviorSubject } from "rxjs";
import { Router } from "@angular/router";

import { environment } from "../../../../../../environments/environment";
import { map, take, finalize } from "rxjs/operators";
import { AngularFireUploadTask, AngularFireStorage } from "@angular/fire/storage";
import { User } from '../../users/_models/user.model';
import { config } from '../../../../../_shared/_configs/config';
import { AuthenticationService } from '../../../../../auth/_services';
import { FilesService } from '../../../../../_shared/_components/files/files.service';
import { UsersService } from '../../users/_services/users.service';
import { NotificationsService } from 'src/app/_shared/_services/notifications.service';

@Injectable()
export class TeamsService {

  entityId: string;

  authSubscription: Subscription;
  loggedInUser: any;

  constructor(
    public afs: AngularFirestore,
    public router: Router,
    private afAuth: AngularFireAuth,
    private auth: AuthenticationService,
    private filesService: FilesService,
    private usersService: UsersService,
    private notificationsService: NotificationsService
  ) {
    this.entityId = config.entityId;
    this.authSubscription = this.auth.user.subscribe(userDetails => {
      if (userDetails) {
        this.loggedInUser = userDetails;
      }
    });
  }

  getTeams() {
    const teamsCollection = this.afs.collection(`entities/${this.entityId}/teams`, ref => ref.orderBy('name', 'asc'));
    return teamsCollection.valueChanges({ idField: 'id' });
  }

  getTeamsForUser(userId) {
    return this.afs.collection(`users/${userId}/entities/${config.entityId}/teams/`, ref => ref.where('active', '==', true)).valueChanges();
  }

  getTeam(teamId) {
    const teamsDoc = this.afs.doc(`entities/${this.entityId}/teams/${teamId}`);
    return teamsDoc.valueChanges();
  }

  getTeamUsers(teamId) {
    const teamUsersCollection = this.afs.collection(`entities/${this.entityId}/teams/${teamId}/users`, ref => ref.where('active', '==', true));
    return teamUsersCollection.valueChanges({ idField: 'id' });
  }

  addTeam(teamInfo) {

    const teamsCollection = this.afs.collection(`entities/${this.entityId}/teams`);
    let members = teamInfo.users;

    // SET PREFIX FOR REF NO
		const referencePrefix = teamInfo.basicInfo.name.substring(0, 3).toUpperCase();

    // SET TEAM DOC DATA
    let teamData = {
      active: true,
      created: new Date(),
      createdBy: this.loggedInUser.uid,
      name: teamInfo.basicInfo.name,
      description: teamInfo.basicInfo.description,
      billingInfo: teamInfo.billingInfo,
      status: 1,
      referencePrefix: referencePrefix,
      owner: teamInfo.owner,
      source: config.source,
      verified: true
    }

    teamInfo.name = teamInfo.basicInfo.name;
    teamInfo.description = teamInfo.basicInfo.description;

    return teamsCollection.add(teamData).then(ref => {
      const teamsDoc = this.afs.doc(`entities/${this.entityId}/teams/${ref.id}`);

      return teamsDoc.set({uid: ref.id}, {merge: true}).then(() => {

          // SAVE LOGO
          if (teamInfo.teamLogo) {

            // SET REF PATH TO SAVE
            let saveRef = {
              dbPath: `entities/${config.entityId}/teams/${ref.id}`,
              filePath: `entities/${config.entityId}/teams/${ref.id}/${ref.id}_logo`,
              uploadType: 'cropper',
              thumbSize: 150
            };

            const uploadFiles = this.filesService.handleDrop(teamInfo.teamLogo, saveRef);
            return Promise.resolve(uploadFiles).then(() => {
              if (members) {
                members.forEach(member => {
                  this.linkUsersAndTeams(member, teamData, ref.id);
                });
              }
            });
          } else {
            if (members) {
              members.forEach(member => {
                this.linkUsersAndTeams(member, teamData, ref.id);
              });
            }
          }

      });
    });

  }

  linkUsersAndTeams(member, teamData, refId) {

    // LINK USER TO TEAM
    if (member.invited) {
      return this.usersService.inviteUser(member).then(userRef => {
        if (teamData.owner.uid === member.uid) {
          member.isOwner = true;
        }

        // LINK USER TO TEAM
        let linkData = member;
        linkData.uid = userRef;
        linkData.teamId = refId;
        linkData.name = teamData.name;
        linkData.verified = teamData.verified;
        linkData.status = teamData.status;
        linkData.created = teamData.created;
        return this.addUserToTeam(linkData);
      });
    } else {
        if (teamData.owner.uid === member.uid) {
          member.isOwner = true;
        }

        // LINK USER TO TEAM
        let linkData = member;
        linkData.teamId = refId;
        linkData.name = teamData.name;
        linkData.verified = teamData.verified;
        linkData.status = teamData.status;
        linkData.created = teamData.created;
        return this.addUserToTeam(linkData);
    }
  }

  addUserToTeam(linkData) {

    const teamsUsersDoc = this.afs.doc(`entities/${this.entityId}/teams/${linkData.teamId}/users/${linkData.uid}`);
    const usersTeamsDoc = this.afs.doc(`users/${linkData.uid}/entities/${this.entityId}/teams/${linkData.teamId}`);

    let accepted = (linkData.isOwner) ? true : false;
    let sendUserNotification;

    // SET USER DATA FOR TEAM
    const teamUser = {
      active: true,
      created: new Date(),
      firstname: linkData.firstname,
      surname: linkData.surname,
      email: linkData.email,
      ref: `/users/${linkData.uid}`,
      uid: linkData.uid,
      isAdmin: linkData.isAdmin,
      isStaff: linkData.isStaff,
      isOwner: linkData.isOwner,
      product: '',
      accepted: accepted
    }
    // SET TEAM DATA FOR USER
    const userTeam = {
      active: true,
      name: linkData.name,
      ref: `/entities/${this.entityId}/teams/${linkData.teamId}`,
      uid: linkData.teamId,
      isAdmin: linkData.isAdmin,
      isStaff: linkData.isStaff,
      isOwner: linkData.isOwner,
      verified: linkData.verified,
      status: linkData.status,
      created: linkData.created,
      accepted: accepted
    }

    const notification = {
      title: 'Added to Team',
      body: `You have been added to ${linkData.name} team`,
      type: 'teamUserAdd',
      createdBy: this.loggedInUser.uid,
      addToHistory: true,
      userId: linkData.uid,
      customData: {
        createdBy: this.loggedInUser.uid,
        notificationUrl: `/teams/list`
      },
      historyInfo: {
        created: new Date(),
        title: 'Added to Team',
        message: `You have been added to '<strong>${linkData.name}</strong>' team`,
        linkText: `View Teams`,
        type: 'teamUserAdd',
        createdBy: this.loggedInUser.uid,
        userId: linkData.uid,
        url: `/teams/list`,
        listRef: '/my-notifications',
        source: config.source,
        unread: true
      }
    }

    const setTeamUser = teamsUsersDoc.set(teamUser, { merge: true });
    const setUserTeam = usersTeamsDoc.set(userTeam, { merge: true });

    if (!accepted) {
      sendUserNotification = this.notificationsService.addNotification(notification, linkData);
    }

		return Promise.all([setTeamUser, setUserTeam, sendUserNotification]);
  }

  updateTeam(team, teamId) {
    const teamsDoc = this.afs.doc(`entities/${this.entityId}/teams/${teamId}`);
      return teamsDoc
          .set(team, { merge: true })
          .then(() => {

          })
          .catch(error => {

          });
  }

  updateTeamUser(userId, teamId, updateValues) {
    console.log(userId, teamId, updateValues)
    const teamsUsersDoc = this.afs.doc(`entities/${this.entityId}/teams/${teamId}/users/${userId}`);
    const usersTeamsDoc = this.afs.doc(`users/${userId}/entities/${this.entityId}/teams/${teamId}`);

    const setTeamUser = teamsUsersDoc.set(updateValues, { merge: true });
    const setUserTeam = usersTeamsDoc.set(updateValues, { merge: true });

		return Promise.all([setTeamUser, setUserTeam]);
  }

  deleteTeamUser(userId, teamId, teamName) {
    const teamsUsersDoc = this.afs.doc(`entities/${this.entityId}/teams/${teamId}/users/${userId}`);
    const usersTeamsDoc = this.afs.doc(`users/${userId}/entities/${this.entityId}/teams/${teamId}`);

    const deletedUser = {
      uid: userId
    }

    const notification = {
      title: 'Removed from Team',
      body: `You have been removed from ${teamName} team`,
      type: 'teamUserRemoved',
      createdBy: this.loggedInUser.uid,
      addToHistory: true,
      userId: userId,
      customData: {
        createdBy: this.loggedInUser.uid,
        notificationUrl: `/teams/list`
      },
      historyInfo: {
        created: new Date(),
        title: 'Removed from Team',
        message: `You have been removed from '<strong>${teamName}</strong>' team`,
        linkText: `View Teams`,
        type: 'teamUserRemoved',
        createdBy: this.loggedInUser.uid,
        userId: userId,
        url: `/teams/list`,
        listRef: '/my-notifications',
        source: config.source,
        unread: true
      }
    }

    const sendUserNotification = this.notificationsService.addNotification(notification, deletedUser);
    const setTeamUser = teamsUsersDoc.delete();
    const setUserTeam = usersTeamsDoc.delete();

		return Promise.all([setTeamUser, setUserTeam, sendUserNotification]);
  }

  acceptTeamInvite(teamData, user, accepted) {
    const teamDoc = this.afs.doc(teamData.ref);
    const teamUsersDoc = this.afs.doc(`entities/${this.entityId}/teams/${teamData.uid}/users/${user.uid}`);
    const usersTeamsDoc = this.afs.doc(`users/${user.uid}/entities/${this.entityId}/teams/${teamData.uid}`);
    let teamOwner;
    return teamDoc.ref.get().then((team: any) => {

      teamOwner = team.data().owner;

      let notification = {
        title: 'Accepted Team Invite',
        body: `${user.firstname} ${user.surname} accepted your invite to ${teamData.name}`,
        type: 'teamUserAdded',
        createdBy: user.uid,
        addToHistory: true,
        userId: teamOwner.uid,
        customData: {
          createdBy: user.uid,
          notificationUrl: `/teams/edit/${teamData.uid}`
        },
        historyInfo: {
          created: new Date(),
          title: 'Accepted Team Invite',
          message: `${user.firstname} ${user.surname} accepted your invite to '<strong>${teamData.name}</strong>'`,
          linkText: `View Team`,
          type: 'teamUserAdded',
          createdBy: user.uid,
          userId: teamOwner.uid,
          url: `/teams/edit/${teamData.uid}`,
          listRef: '/my-notifications',
          source: config.source,
          unread: true
        }
      }

      if (!accepted) {
        notification.title = 'Declined Team Invite';
        notification.body = `${user.firstname} ${user.surname} declined your invite to ${teamData.name}`;
        notification.type = `teamUserDeleted`;
        notification.historyInfo.title = 'Declined Team Invite';
        notification.historyInfo.message = `${user.firstname} ${user.surname} declined your invite to '<strong>${teamData.name}</strong>'`;
        notification.historyInfo.type = `teamUserDeleted`;
      }

      const setTeamUser = (accepted) ? teamUsersDoc.set({accepted: accepted}, {merge:true}) : teamUsersDoc.delete();
      const setUserTeam = (accepted) ? usersTeamsDoc.set({accepted: accepted}, {merge:true}) : usersTeamsDoc.delete();
      const sendUserNotification = this.notificationsService.addNotification(notification, teamOwner);

      return Promise.all([setTeamUser, setUserTeam, sendUserNotification]);
    });
  }

}
