import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { ThemeComponent } from './theme.component';
import { ThemeRoutingModule } from './theme-routing.module';
import { environment } from 'src/environments/environment';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AppCoreModule } from '../_shared/app-core.module';
import { LayoutModule } from './layouts/layout.module';

@NgModule({
  declarations: [
    ThemeComponent
  ],
  imports: [
    CommonModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    ThemeRoutingModule,
    AppCoreModule.forRoot(),
    NgxPermissionsModule.forChild(),
    LayoutModule
  ],
  exports: [
    ThemeComponent
  ],
  bootstrap: [ThemeComponent]
})
export class ThemeModule {
}
