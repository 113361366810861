export const packages = [
  {
    name: '2-5 Members',
    discount: 10,
    max: 5,
    refNo: 'TEAMPKG1',
    price: 1299,
    info: '1x Elite / 4x GENERAL'
  },
  {
    name: '6-10 Members',
    discount: 12,
    max: 10,
    refNo: 'TEAMPKG2',
    price: 2599,
    info: '2x Elite / 4x GENERAL / 4x TOWNSHIP'
  },
  {
    name: '11-15 Members',
    discount: 13,
    max: 15,
    refNo: 'TEAMPKG3',
    price: 3599,
    info: '2x Elite / 12x GENERAL / 1x TOWNSHIP'
  },
  {
    name: '16-25 Members',
    discount: 15,
    max: 25,
    refNo: 'TEAMPKG4',
    price: 5599,
    info: '5x Elite / 20x GENERAL / 5x TOWNSHIP'
  }
];
