import { AuthenticationService } from "src/app/auth/_services";
import { MessagesService } from "./../../../_services/messages.service";
import { Component, OnInit, Input } from "@angular/core";
import { StorageService } from "src/app/_shared/_services/storage.service";
import { Subject, Subscription } from "rxjs";
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

declare var $: any;

@Component({
  selector: "app-modal-user-conversation",
  templateUrl: "./modal-user-conversation.html",
  styleUrls: ["./model-user-component.scss"],
})
export class ModalUserConversationComponent implements OnInit {
  @Input() modalData: any;
  messagesSubscription: Subscription;
  messagesList: any;
  conversationId: string = 'wealthspaces';
  message: string;
  messageObj:object;
  currentUserId: string;

  constructor(
    public storageService: StorageService,
    private messagesService: MessagesService,
    private auth: AuthenticationService
  ) {}

  ngOnInit() {
    this.currentUserId = this.auth.userId;
    this.conversationId = (this.modalData.data.userId) ? this.modalData.data.userId : 'wealthspaces';
    this.createDefaultMessageObj();
    $("#userConversationModal").modal("show");
    this.messagesSubscription = this.messagesService
      .getMessagesForConversation(
        this.auth.userDetails.uid,
        this.conversationId
      )
      .subscribe((messages) => {
        this.messagesList = messages;
        if (messages.length === 0) {
          this.messagesService.createDefaultConversation(
            this.auth.userDetails.uid,
            this.conversationId,
            (this.modalData.data.locationId) ? this.modalData.data.locationId : null
          );
        }
        
        setTimeout(function () {
          if (messages.length > 0) {
            const lastMessage = document.getElementById(
              messages[messages.length - 1]["id"]
            );
            if (lastMessage) {
              lastMessage.scrollIntoView();
            }
          }
        }, 500);
      });
  }

  addMessage(newMessageForm) {
    if (newMessageForm.message) {
      this.messageObj['text'] = newMessageForm.message;
      this.messageObj['created'] = new Date();
      this.messageObj['createdById'] = this.auth.userId;
      this.messageObj['createdByName'] = this.auth.userDetails.firstname + " " + this.auth.userDetails.surname;
      this.messageObj['createdByImage'] = this.auth.userDetails.photoURL;
      this.messageObj['type'] = 'text';
      this.messagesService
        .addMessageToConversation(
          this.auth.userDetails.uid,
          this.conversationId,
          this.messageObj
        )
        .then(() => {
          newMessageForm.message = "";
          this.message = "";
          this.createDefaultMessageObj();
          newMessageForm.reset();
        });
    }
    else
    {
      console.log ("No message found.");
    }
  }

  createDefaultMessageObj() {
    this.messageObj = 
    {
      text : 'Default message',
      created  :  new Date(),
      createdById  :  'wealthspaces',
      createdByName  :  "WealthSpaces",
      type  :  'text',
      createdByImage  :  "/assets/images/icon/WS-ICON.png",
    }
  }

  ngOnDestroy() {
    if (this.messagesSubscription) {
      this.messagesSubscription.unsubscribe();
    }
  }

  onModalHide() {
    $("#userConversationModal").on("hidden.bs.modal", () => {
      this.destroyModal();
    });
  }

  hideModal() {
    $("#userConversationModal").modal("hide");
  }

  closeModal(value) {
    if (value) {
      this.hideModal();
    }
  }

  destroyModal() {
    const modalInfo = {};
    this.storageService.openModal(modalInfo);
  }
}

