import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { User } from '../../../../../theme/pages/wealth-spaces/users/_models/user.model';
import { StorageService } from 'src/app/_shared/_services/storage.service';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'ws-locations-filter',
  templateUrl: './ws-locations-filter.component.html'
})
export class WSLocationsFilterComponent implements OnInit, OnDestroy {

  locationsSubscription: Subscription;
  locations: any[];
  allLocations: any[];

  region = '';
  city = '';
  space = '';
  parking = '';
  noise = '';
  amenity = '';

  //USER
  userSubscription: Subscription;
  user: User;

  constructor(
    private storageService: StorageService
    ) {}

  ngOnInit() {
    this.locationsSubscription = this.storageService.locations.subscribe(locationsData => {
      this.locations = locationsData;
      this.allLocations = locationsData;
    });

    this.userSubscription = this.storageService.userDetails.subscribe(user => {
      if (Object.keys(user).length > 0) {
        this.user = user;
      }
    });
  }

  filterLocations(value, type) {

    let address;
    let zoom;

    if (this.city) {
      address = this.city;
      zoom = 10;
    } else if (this.region) {
      address = this.region
      zoom = 8;
    } else {
      address = 'South Africa'
      zoom = 6;
    }

    if (type === 'region') {
      this.amenity = '';
      if (value !== '') {
        this.locations = this.allLocations.filter((location) => {
          return location.address.region == value;
        });
        this.storageService.mapLocations.next(this.locations);
        this.storageService.locationFilter.next({
          address: value,
          zoom: 8
        });
      } else {
        this.locations = this.allLocations;
        this.storageService.mapLocations.next(this.locations);
        this.storageService.locationFilter.next({
          address: 'South Africa',
          zoom: 6
        });
      }
    }

    if (type === 'city') {
      this.amenity = '';
      if (value !== '') {
        this.locations = this.allLocations.filter((location) => {
          return location.address.city == value;
        });
        this.storageService.mapLocations.next(this.locations);
        this.storageService.locationFilter.next({
          address: value,
          zoom: 10
        });
      } else {
        this.locations = this.allLocations;
        this.storageService.mapLocations.next(this.locations);
        this.storageService.locationFilter.next({
          address: address,
          zoom: zoom
        });
      }
    }

    if (type === 'space') {
      this.amenity = '';
      if (value !== '') {
        this.locations = this.allLocations.filter((location) => {
          return location.availableSpaces == value;
        });
        this.storageService.mapLocations.next(this.locations);
        this.storageService.locationFilter.next({
          address: address,
          zoom: zoom
        });
      } else {
        this.locations = this.allLocations;
        this.storageService.mapLocations.next(this.locations);
        this.storageService.locationFilter.next({
          address: address,
          zoom: zoom
        });
      }
    }

    if (type === 'parking') {
      this.amenity = '';
      if (value !== '') {
        this.locations = this.allLocations.filter((location) => {
          return location.parking == value;
        });
        this.storageService.mapLocations.next(this.locations);
        this.storageService.locationFilter.next({
          address: address,
          zoom: zoom
        });
      } else {
        this.locations = this.allLocations;
        this.storageService.mapLocations.next(this.locations);
        this.storageService.locationFilter.next({
          address: address,
          zoom: zoom
        });
      }
    }

    if (type === 'noise') {
      this.amenity = '';
      if (value !== '') {
        this.locations = this.allLocations.filter((location) => {
          return location.noiseLevel == value;
        });
        this.storageService.mapLocations.next(this.locations);
        this.storageService.locationFilter.next({
          address: address,
          zoom: zoom
        });
      } else {
        this.locations = this.allLocations;
        this.storageService.mapLocations.next(this.locations);
        this.storageService.locationFilter.next({
          address: address,
          zoom: zoom
        });
      }
    }

    if (type === 'amenity') {
      if (value !== '') {
        this.locations = this.allLocations.filter((location) => {
          return location.amenities.some(amenity => amenity.name === value)
        });
        this.storageService.mapLocations.next(this.locations);
        this.storageService.locationFilter.next({
          address: address,
          zoom: zoom
        });
      } else {
        this.locations = this.allLocations;
        this.storageService.mapLocations.next(this.locations);
        this.storageService.locationFilter.next({
          address: address,
          zoom: zoom
        });
      }
    }

  }

  resetFilters() {
    this.region = '';
    this.city = '';
    this.space = '';
    this.parking = '';
    this.noise = '';
    this.amenity = '';
    this.locations = this.allLocations;
    this.storageService.mapLocations.next(this.locations);
    this.storageService.locationFilter.next({
      address: 'South Africa',
      zoom: 6
    });
  }

  getParking(parking) {
    switch (parking) {
      case 'FO':
        return `Free Outdoor Parking`;
        break;
      case 'FI':
        return `Free Indoor Parking`;
        break;
      case 'PP':
        return `Paid Parking`;
        break;
      case 'NP':
        return `No Parking`;
        break;
      default:
    }
  }

  ngOnDestroy() {
    this.locationsSubscription.unsubscribe();
    this.userSubscription.unsubscribe();
  }

}
