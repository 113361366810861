import {Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'userSearch'
})
export class UserSearchPipe implements PipeTransform {
    transform(items: Array<any>, nameSearch: string, emailSearch: string){
        if (items && items.length){
            return items.filter(item =>{
                if (nameSearch && item.full_name.toLowerCase().indexOf(nameSearch.toLowerCase()) === -1){
                    return false;
                }
                if (emailSearch && item.email.toLowerCase().indexOf(emailSearch.toLowerCase()) === -1){
                    return false;
                }
                return true;
           })
        }
        else{
            return items;
        }
    }
}
