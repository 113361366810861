import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalsComponent } from './modals.component';
import { ModalProductsComponent } from './modals-products/modal-products';
import { WealthSpacesCompModule } from '../wealthspaces/wealthspaces.module';
import { ModalBookingRoomComponent } from './modals-booking-room/modal-booking-room';
import { ModalEventsComponent } from './modals-events/modal-events';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalBookingsComponent } from './modals-bookings/modal-bookings';
import { ModalBookingLocationComponent } from './modals-booking-location/modal-booking-location';
import { ModalUsersComponent } from './modals-users/modal-users';
import { ModalQrCodeComponent } from './modals-qr-code/modal-qr-code';
import { ModalUserConversationComponent } from './modals-user-conversation/modal-user-conversation';
import { QrcModule } from '../qr-code/qr-code.module';

@NgModule({
  declarations: [
    ModalsComponent,
    ModalProductsComponent,
    ModalBookingRoomComponent,
    ModalEventsComponent,
    ModalBookingsComponent,
    ModalBookingLocationComponent,
    ModalUsersComponent,
    ModalQrCodeComponent,
    ModalUserConversationComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    WealthSpacesCompModule,
    NgbModule,
    QrcModule
  ],
  exports: [
    ModalsComponent,
    ModalProductsComponent,
    ModalBookingRoomComponent,
    ModalEventsComponent,
    ModalBookingsComponent,
    ModalBookingLocationComponent,
    ModalUsersComponent,
    ModalQrCodeComponent,
    ModalUserConversationComponent
  ],
  providers: []
})
export class ModalsModule {
}
