export const environment = {
    production: false,
    firebase: {
      apiKey: "AIzaSyAntIr_s0Tzogubs_IcC4yUNk32Sq5x_00",
      authDomain: "wealthspaces-admin-dev.firebaseapp.com",
      databaseURL: "https://wealthspaces-admin-dev.firebaseio.com",
      projectId: "wealthspaces-admin-dev",
      storageBucket: "wealthspaces-admin-dev.appspot.com",
      messagingSenderId: "783930452766",
      appId: "1:783930452766:web:1996fab97cf71121fe370f",
      measurementId: "G-PTG3SC8CH3"
    },
    product: 'wealthspaces',
    port: 8083,
    websiteUrl: 'https://clients.ws.noltech.co.za/',
    adminUrl: 'https://admin.ws.noltech.co.za/',
    clientUrl: 'https://clients.ws.noltech.co.za/'
};
  