import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { WSLocationsFilterComponent } from './ws-locations-filter/ws-locations-filter.component';
import { NgPipesModule } from 'ngx-pipes';

@NgModule({
  declarations: [
    WSLocationsFilterComponent
  ],
  imports: [
    NgPipesModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule
  ],
  exports: [
    WSLocationsFilterComponent
  ],
  providers: []
})
export class WsLocationsModule {
}
