import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FilesService } from '../files.service';

@Component({
    selector: 'app-file-list',
    templateUrl: './file-list.component.html',
    styleUrls: ['./file-list.component.scss']
})
export class FileListComponent implements OnInit {

    entityID: string;
    files;
    loadingFiles:boolean = true;

    @Input() filesRef: string;
    @Output() filesReturned = new EventEmitter();

    fileExtensions = {
        'pdf': 'far fa-file-pdf',
        'jpeg': 'far fa-file-image',
        'jpg': 'far fa-file-image',
        'png': 'far fa-file-image',
        'gif': 'far fa-file-image',
        'doc': 'far fa-file-word',
        'docx': 'far fa-file-word',
        'xls': 'far fa-file-excel',
        'xlsx': 'far fa-file-excel',
        'ppt': 'far fa-file-powerpoint',
        'pptx': 'far fa-file-powerpoint',
        'rar': 'far fa-file-archive',
        'zip': 'far fa-file-archive',
        'txt': 'far fa-file-text'
    };

    constructor(public filesService: FilesService) {}

    ngOnInit() {
        this.loadingFiles = true;
        this.filesService.getFiles(this.filesRef).subscribe(files => {
            this.files = files;
            this.filesReturned.emit(files);
            this.loadingFiles = false;
        });
    }

    removeFile(file) {
        this.filesService.removeFile(this.filesRef, file.id);
    }

    calculateFileSize = function (size) {
        if (!size)
        {
            return "?";
        }
        else if (size<1000)
        {
            return size+'b';
        }
        else if ( size > 1000 && size < 1000000)
        {
            return Math.round(size / 1000)+'kb';
        }
        else if ( size > 1000000)
        {
            return Math.round(size / 1000000)+'mb';
        }
        else
        {
            return "?";
        }

    }
}
