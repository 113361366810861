import { Component, OnInit, Input } from '@angular/core';
import { StorageService } from 'src/app/_shared/_services/storage.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbDate, NgbCalendar, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BookingsService } from '../../wealthspaces/ws-bookings/_services/bookings.service';
import * as moment from 'moment';
import { Subscription } from 'rxjs';

declare var $: any;

@Component({
  selector: 'app-modal-booking-location',
  templateUrl: './modal-booking-location.html',
})
export class ModalBookingLocationComponent implements OnInit {

  bookLocationForm: FormGroup;

  @Input() modalData: any;

  hoveredDate: NgbDate;
  meetingDate: NgbDate;
  minDate = undefined;

  formDetails;
  paymentDetails;

  submitting = false;
  showBookingDetails;

  location: any;
  meetingRoomBookings: Subscription;
  allBookingTimes: any[] = [];
  isLoading: boolean = false;
  startTime: { hour: 0, minute: 0 };

  showBookedMessage: boolean = false;

  // DATES
  displayMonths = 2;
  navigation = 'select';
  showWeekNumbers = false;
  outsideDays = 'hidden';
  selectedDates: any[] = [];
  fomattedDates: any[] = [];
  disabledDays: any[] = [];
  businessHours: any;
  currentDayTime: any;

  // USER BOOKINGS
  userBookings: NgbDateStruct[] = [];

  constructor(
    public storageService: StorageService,
    private fb: FormBuilder,
    public calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    private toastr: ToastrService,
    private bookingsService: BookingsService,
  ) {
  }

  ngOnInit() {
    this.isLoading = true;
    console.log('modal stuff', this.modalData)
    $("#bookingLocationModal").modal("show");
    // SET DATE RANGE
    const currentDate = new Date();
    this.minDate = {
      year: currentDate.getFullYear(),
      month: currentDate.getMonth() + 1,
      day: currentDate.getDate()
    };
    this.location = this.modalData.data.location;
    this.businessHours = this.modalData.data.businessHours;
    this.currentDayTime = this.modalData.data.currentDayTime;
    this.disabledDays = this.modalData.data.userBookings;
    console.log(this.modalData.data.userBookings)
    this.markDisabledDays(this.businessHours);
    this.buildForm();
    this.meetingRoomBookings = this.bookingsService.getBookingsType(this.location.uid, 'locations').subscribe((bookingsData: any) => {
      console.log(bookingsData);
      bookingsData.forEach((booking) => {
        if (booking.start.toDate() > currentDate) {
          const times = {
            date: booking.start.getDate() + '/' + booking.start.getMonth() + '/' + booking.start.getYear(),
            start: booking.start.getTime(),
            end: booking.end.getTime()
          }
          this.allBookingTimes.push(times)
        }
      });
      this.isLoading = false;
    });
  }

  buildForm() {
    this.bookLocationForm = this.fb.group({
      dates: ['', Validators.required]
    });
   // this.onValueChanges();
  }

  markDisabledDays(businessHours) {
    businessHours.monday.day = 1;
    businessHours.tuesday.day = 2;
    businessHours.wednesday.day = 3;
    businessHours.thursday.day = 4;
    businessHours.friday.day = 5;
    businessHours.saturday.day = 6;
    businessHours.sunday.day = 7;
    let filtered = Object.values(businessHours);
    filtered.forEach((day: any) => {
      if (JSON.stringify(day.open) === JSON.stringify(day.close)) {
        this.disabledDays.push(day.day);
      }
    });
  }

  markDisabled = (date: NgbDate)=> {
    return this.disabledDays.find(x=> this.calendar.getWeekday(date) === x || NgbDate.from(x).equals(date));
  }

  onDateSelection(date: NgbDate) {

    let formattedDate = `${date.day}/${date.month}/${date.year}`;

    if (!this.fomattedDates.includes(formattedDate)) {
      this.selectedDates.push(date);
      this.fomattedDates.push(formattedDate);
    } else {
      this.selectedDates.splice( this.selectedDates.indexOf(date), 1 );
      this.fomattedDates.splice( this.fomattedDates.indexOf(formattedDate), 1 );
    }

    this.bookLocationForm.get('dates').setValue(this.selectedDates);

    console.log(this.bookLocationForm.get('dates').value)
  }

  selectDays(date: NgbDateStruct) {
    return this.selectedDay(date);
  }

  selectedDay(date: NgbDateStruct): boolean {
    for (var i = 0; i < this.selectedDates.length; i++) {
      var locationDate = this.selectedDates[i];
      if (locationDate.day === date.day && locationDate.month === date.month && locationDate.year === date.year) {
        return true;
      }
    }
  }

  onSubmit() {
    this.submitting = true;
    this.formDetails = this.bookLocationForm.value;
    console.log(this.currentDayTime)
    const valueArray: any = [];
    this.formDetails.dates.forEach((value: any, index, array) => {
      const startDate: any = new Date(
        value.year,
        value.month - 1,
        value.day,
        parseInt(this.pad(this.currentDayTime.open.hour)),
        parseInt(this.pad(this.currentDayTime.open.minute)),
        parseInt(this.pad(0)));

      const endDate: any = new Date(
        value.year,
        value.month - 1,
        value.day,
        parseInt(this.pad(this.currentDayTime.close.hour)),
        parseInt(this.pad(this.currentDayTime.close.minute)),
        parseInt(this.pad(0)));
        this.bookingsService.createUserLocationBooking(startDate, endDate, this.location).then(() => {
          valueArray.push(value);
          if (valueArray.length === array.length) {
            this.toastr.success('Booking has been requested!');
            this.submitting = false;
            this.closeModal(true);
          }
        }).catch(err => {
          valueArray.push(value);
          if (valueArray.length === array.length) {
            console.log("ModalBookingRoomComponent -> onSubmit -> err", err)
            this.toastr.error('There was an error processing you request.');
            this.submitting = false;
          }
        })
    });
  }

  private pad(i: number): string {
      return i < 10 ? `0${i}` : `${i}`;
  }

  ngOnDestroy() {
  }

  onModalHide() {
    $('#bookingLocationModal').on("hidden.bs.modal", () => {
      this.destroyModal();
    });
  }

  hideModal() {
    $('#bookingLocationModal').modal('hide');
  }

  closeModal(value) {
    if (value) {
      this.hideModal();
    }
  }

  destroyModal() {
    const modalInfo = {};
    this.storageService.openModal(modalInfo);
  }

}
