import { Injectable } from "@angular/core";
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from "@angular/fire/firestore";
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, Subject, Subscription, combineLatest, BehaviorSubject } from "rxjs";
import { Router } from "@angular/router";

import { environment } from "../../../../../../environments/environment";
import { map, take, finalize } from "rxjs/operators";
import { AngularFireUploadTask, AngularFireStorage } from "@angular/fire/storage";
import { User } from '../_models/user.model';
import { config } from '../../../../../_shared/_configs/config';

declare var toastr: any;

@Injectable()
export class UsersService {

  entityId: string;

  userDoc: AngularFirestoreDocument<User>;
  usersCollection: AngularFirestoreCollection<User[]>;
  entityUsersCollection: AngularFirestoreCollection<any>;
  public userId = new BehaviorSubject<string>('');
  public userInfo = new BehaviorSubject<User>({});

  constructor(
    public afs: AngularFirestore,
    public router: Router,
    private afAuth: AngularFireAuth,
  ) {
    this.entityId = config.entityId;
  }

  fetchUserDetails(ref: string) {
    this.userDoc = this.afs.doc(ref);
    return this.userDoc.valueChanges();
  }

  fetchEntityUsers() {
      const entityUsersCollection = this.afs.collection(`entities/${this.entityId}/users`);

      // FETCH ALL ENTITY USERS
      return entityUsersCollection.snapshotChanges().pipe(map(changes => {
          return changes.map(a => {

            const data = a.payload.doc.data() as any;
            data.full_name = `${data.firstname} ${data.surname}`;

            // FETCH EXTRA USER INFO
            this.afs.doc(`users/${a.payload.doc.id}`).ref.get().then((user: any) => {
              if (user.data()) {

                // FETCH USERS VERIFICATION

                if (user.data().firebaseId) {
                  return this.afs.doc(`userRefs/${user.data().firebaseId}`).ref.get().then((userRef: any) => {
                    if (userRef.data()) {
                        data.verified = userRef.data().verified;
                        data.socialLogin = userRef.data().socialLogin;
                        data.signInMethod = userRef.data().signInMethod;
                        data.photoURL = user.data().photoURL;
                        data.uploadedPhoto = user.data().uploadedPhoto;

                        // FETCH USERS PERMISSIONS
                        return this.afs.doc(`users/${a.payload.doc.id}/entities/${this.entityId}`).ref.get().then((permissions: any) => {
                          if (permissions.data()) {
                              data.permissions = permissions.data().permissions;
                          }
                        });
                    }
                  });
                } else {

                  // FETCH USERS PERMISSIONS
                  return this.afs.doc(`users/${a.payload.doc.id}/entities/${this.entityId}`).ref.get().then((permissions: any) => {
                    if (permissions.data()) {
                        data.permissions = permissions.data().permissions;
                    }
                  });

                }
              }
            });

            return data;
          });
      }));
  }

  findUsers() {
    const entityUsersCollection = this.afs.collection(`entities/${this.entityId}/users`, ref => ref.where('active', '==', true).where('status', '==', 1));
    return entityUsersCollection.snapshotChanges().pipe(map(changes => {
      return changes.map(a => {
        const data = a.payload.doc.data() as User;
        let refNo = '';
        let photoURL = '';
        let uploadedPhoto = '';
        let uploadedPhotoThumbnail = '';
        refNo = (data.refNo) ? data.refNo : refNo;
        photoURL = (data.photoURL) ? data.photoURL : photoURL;
        uploadedPhoto = (data.uploadedPhoto) ? data.uploadedPhoto : uploadedPhoto;
        uploadedPhotoThumbnail = (data.uploadedPhotoThumbnail) ? data.uploadedPhotoThumbnail : uploadedPhotoThumbnail;
        let user = {
          firstname: data.firstname,
          surname: data.surname,
          email: data.email,
          contactNumber: data.contactNumber,
          uid: data.uid,
          refNo,
          photoURL,
          uploadedPhoto,
          uploadedPhotoThumbnail
        };
        return user;
      });
    }));
  }

  fetchUsers() {
      const entityUsersCollection = this.afs.collection(`users`);
      return entityUsersCollection.snapshotChanges().pipe(map(changes => {
          return changes.map(a => {
              const data = a.payload.doc.data() as any;
              data.full_name = `${data.firstname} ${data.surname}`;
              return data;
          });
      }));
  }

  fetchInvitedUsers() {
      const invitedUsersCollection = this.afs.collection(`pendingUserInvites`, ref => ref.where("entityId", "==", this.entityId));
      return invitedUsersCollection.snapshotChanges().pipe(map(changes => {
          return changes.map(a => {
              const data = a.payload.doc.data() as any;
              data.full_name = `${data.firstname} ${data.surname}`;
              return data;
          });
      }));
  }

  fetchUserRefDetails(firebaseId: string) {
    const userRefsRef = this.afs.doc(`userRefs/${firebaseId}`);
    return userRefsRef.valueChanges();
  }

  updateUser(user: User) {

    const updateUser = this.afs.doc(`/pending/${user.uid}`);

    return updateUser.set({
      request: 'updateUserDetails',
      user: user,
      entityId: this.entityId,
      source: 'admin',
      firebaseId: user.firebaseId
    }).then(() => {

      // toastr.success("Your Profile has been updated!");
      let logData = {
        name: user.email,
        description: 'User was updated',
        type: 'update',
        category: 'user',
        created: Date.now()
      }
    });
  }

  updateUserPermission(userUID, permissions: any) {

    // SET PERMISSIONS
    let addPermissions = [];
    if (permissions) {
      addPermissions = ['user', permissions];
    } else {
      addPermissions = ['user'];
    }
    const data = {
        permissions: addPermissions
    };

      const permissionDoc = this.afs
          .collection("users")
          .doc(userUID)
          .collection("entities")
          .doc(this.entityId);
      permissionDoc.update(data);
  }

  updateInvitedUser(user: User) {

    if (user.permissions) {
      user.permissions = ['user', user.permissions];
    } else {
      user.permissions = ['user'];
    }

    const updateUser = this.afs.doc(`/pendingUserInvites/${user.uid}`);
    return updateUser.set(user, {merge: true}).then(() => {

      // toastr.success("Your Profile has been updated!");
      let logData = {
        name: user.email,
        description: 'User was updated',
        type: 'update',
        category: 'user',
        created: Date.now()
      }
    });
  }

  addHistoryLogToUser(userLog, userId) {
    const entityID = this.entityId;
    const propertyHistoryCollection = this.afs.collection(`entities/${entityID}/users/${userId}/history`);
    let logData = {
      userId: userId,
      created: Date.now(),
      changed: userLog
    }
    return propertyHistoryCollection.add(logData);;
  }

  addPendingUserUpdates(logData) {
    const entityID = this.entityId;
    const pendingUpdatesCollection = this.afs.collection(`pending`);
    logData.request = 'notifyReceiveUserUpdates';
    logData.entityId = entityID;
    pendingUpdatesCollection.add(logData).catch((err) => {
      console.log(err);
    });
  }

  inviteUser(userData) {

    // SET PERMISSIONS
    let permissions = [];
    if (userData.permissions) {
      permissions = ['user', userData.permissions];
    } else {
      permissions = ['user'];
    }

		// SET USER REF PREFIX
		const firstname = userData.firstname.toUpperCase();
		const shortLastname = userData.surname.substring(0, 1).toUpperCase();
		const referencePrefix = firstname + shortLastname;

    // SET TEMP USER
    let tmpUserData = {
        firstname: userData.firstname,
        surname: userData.surname,
        email: userData.email,
        contactNumber: userData.contactNumber,
        active: true,
        referencePrefix: referencePrefix
    }

    // CHECK IF USER EXISTS
    const usersCollection = this.afs.collection(`users`, ref => ref.where('email', '==', tmpUserData.email));
    return usersCollection.snapshotChanges().pipe(
        map(changes => {
            return changes.map(a => {
                const data = a.payload.doc.data() as User;
                return data;
            });
        }),
        take(1))
        .toPromise()
        .then(usersList => {
            if (usersList.length === 0) {
              return usersCollection.add(tmpUserData).then(ref => {
                return this.linkUserEntity(this.entityId, ref.id, tmpUserData, permissions).then(() => {
                    return Promise.resolve(ref.id);
                });
              });
            } else {
              // ADD EXISTING USER

              // CHECK IF USER ALREADY ADDED TO ENTITY
              this.entityUsersCollection = this.afs
                  .collection("entities")
                  .doc(this.entityId)
                  .collection("users", ref => ref.where("uid", "==", usersList[0].uid).where("active", "==", true));

              return this.entityUsersCollection
                  .snapshotChanges().pipe(
                      map(changes => {
                          return changes.map(a => {
                              const data = a.payload.doc.data() as User;
                              data.uid = a.payload.doc.id;
                              return data.uid;
                          });
                      }),
                      take(1))
                  .toPromise()
                  .then(entityUsersList => {
                      if (entityUsersList.length === 0) {
                          // USER NOT ADDED TO ENTITY SO CAN ADD USER
                          return this.linkUserEntity(this.entityId, usersList[0].uid, usersList[0], permissions).then(() => {
                              return Promise.resolve(usersList[0].uid);
                          });
                      } else {
                          // USER ALREADY ADDED TO ENTITY SO DISPLAY ERROR
                          return Promise.reject([`The user already exists`]);
                      }
                  });
            }
        });
  }

  linkUserEntity(entityID, userID, userData, permissions) {
    const entityRef = this.afs.collection("entities").doc(entityID);
    const userRef = this.afs.collection("users").doc(userID);
    const pendingUserInvitesRef = this.afs.collection("pendingUserInvites").doc(userID);
    const pendingEmailEntityInviteRef = this.afs.collection("pending");
    const userRefsRef = this.afs.collection("userRefs", ref => ref.where("email", "==", userData.email));
    let usersCount = 0;

    // LINK ENTITY TO USER
    const linkEntityToUser = entityRef
        .snapshotChanges().pipe(
            take(1))
        .toPromise()
        .then(snap => {
            const entityDetails = snap.payload.data() as any;
            usersCount = entityDetails.usersCount;

            userRef
                .collection("entities")
                .doc(entityID)
                .set(
                    {
                        name: entityDetails.name,
                        ref: entityRef.ref,
                        uid: entityID,
                        status: 0,
                        permissions: permissions,
                        active: true
                    },
                    { merge: true }
                );

            // INCREMENT USERS COUNT
            usersCount++;
            entityRef.set(
                {
                    usersCount: usersCount
                },
                { merge: true }
            );
        });

    // LINK USER TO ENTITY
    if (!userData.contactNumber) {
        userData.contactNumber = "";
    }

    const linkUserToEntity = entityRef
        .collection("users")
        .doc(userID)
        .set(
            {
                firstname: userData.firstname,
                surname: userData.surname,
                email: userData.email.toLowerCase(),
                contactNumber: userData.contactNumber,
                ref: userRef.ref,
                uid: userID,
                status: 0,
                active: true
            },
            { merge: true }
        );



    // CREATE PENDING USER INVITE
    const createPendingUserInvite = userRefsRef
        .snapshotChanges().pipe(
            map(changes => {
                return changes.map(a => {
                    const data = a.payload.doc.data() as User;
                    data.uid = a.payload.doc.id;
                    return data.uid;
                });
            }),
            take(1))
        .toPromise()
        .then(userRefsData => {
            if (userRefsData.length === 0) {
                // IF USER REF DOES NOT EXIST CREATE ONE
                return pendingUserInvitesRef.set(
                    {
                        firstname: userData.firstname,
                        surname: userData.surname,
                        email: userData.email.toLowerCase(),
                        contactNumber: userData.contactNumber,
                        ref: userRef.ref,
                        uid: userID,
                        created: Date.now(),
                        entityId: entityID
                    },
                    { merge: true }
                );
            }
        });

    // CREATE PENDING EMAIL ENITY INVITE

    // BASE64 ENCODE USER DATA FOR EMAIL INVITE LINK
    const userDataToEncode = {
        firstname: userData.firstname,
        surname: userData.surname,
        email: userData.email.toLowerCase(),
        contactNumber: userData.contactNumber,
        uid: userID,
        permissions: permissions
    }

    const encodedUserBase64Data = btoa(JSON.stringify(userDataToEncode));

    const createPendingEmailEntityInvite = pendingEmailEntityInviteRef.add({
      request: 'emailEntityInvites',
      entityId: entityID,
      userId: userID,
      userBase64Data: encodedUserBase64Data,
      domain: environment.websiteUrl
    });

    return Promise.all([linkEntityToUser, linkUserToEntity, createPendingUserInvite, createPendingEmailEntityInvite]).then(() => {
        return Promise.resolve(userID);
    });
  }

  emailCheck(email) {
    let collref = this.afs.collection(`entities/${this.entityId}/users`).ref;
    let queryref = collref.where('email', '==', email);
    return queryref.get().then((snapShot) => {
        return snapShot;
    });
  }

}
