import { Component, OnInit, ViewChild, ElementRef, NgZone, Input, Output, EventEmitter } from '@angular/core';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { Subject, Subscription, Observable } from 'rxjs';

declare var google;

@Component({
  selector: 'app-maps-search',
  templateUrl: './maps-search.component.html',
  styleUrls: ['../maps.scss']
})
export class MapsSearchComponent implements OnInit {

  @Output() mapValues = new EventEmitter();
  @Input() mapVariables: any;
  @Input() mapHeight: string;
  @Input() resetFormAddress: Subject<boolean> = new Subject<boolean>();
  @Input() addressDetails: any;

  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  private geoCoder;
  showMap: boolean = false;

  @ViewChild('search', { static: true }) public searchElementRef: ElementRef;

  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone
  ) { }


  ngOnInit() {
    this.resetFormAddress.subscribe(response => {
      if(response){
        this.address = '';
        this.showMap = false;
      }
      // Or do whatever operations you need.
    });

    if (this.addressDetails) {
      this.setCurrentPosition();
    }
    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      // this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ["geocode", "establishment"],
        componentRestrictions: {country: "za"}
      });
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 16;
          this.setNewAddress(this.latitude, this.longitude, place);
        });
      });
    });
  }

  private setCurrentPosition() {
    this.latitude = this.addressDetails.latitude;
    this.longitude = this.addressDetails.longitude;
    this.zoom = 16;
    this.address = this.addressDetails.formatted_address;
    this.showMap = true;
  }

  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = this.addressDetails.latitude;
        this.longitude = this.addressDetails.longitude;
        this.zoom = 16;
        this.address = this.addressDetails.formatted_address;
        this.showMap = true;
        // this.getCurrentAddress(this.latitude, this.longitude);
      });
    }
  }


  markerDragEnd($event: MouseEvent) {
    console.log($event)
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getCurrentAddress(this.latitude, this.longitude);
  }

  getCurrentAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          console.log(results[0])
          this.zoom = 12;
          this.address = results[0].formatted_address;
          let place = results[0];
          this.setNewAddress(latitude, longitude, place)
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }

  setNewAddress(latitude, longitude, place) {

    this.address = place.formatted_address;
    this.showMap = true;

    let componentForm = {
        street_number: 'short_name',
        route: 'long_name',
        locality: 'long_name',
        sublocality_level_1: 'long_name',
        administrative_area_level_1: 'long_name',
        administrative_area_level_2: 'long_name',
        country: 'long_name',
        postal_code: 'short_name'
    };

    let addressDetails = {
        street_number: '',
        route: '',
        locality: '',
        sublocality_level_1: '',
        administrative_area_level_1: '',
        administrative_area_level_2: '',
        country: '',
        postal_code: '',
        physicalAddress: '',
        latitude: latitude,
        longitude: longitude,
        formatted_address: place.formatted_address
    };

    for (var i = 0; i < place.address_components.length; i++) {
      var addressType = place.address_components[i].types[0];

      if (componentForm[addressType]) {
          var val = place.address_components[i][componentForm[addressType]];
          addressDetails[addressType] = val;
      }
    }

    if (addressDetails.street_number != undefined && addressDetails.street_number != "" && addressDetails.street_number != null) {
      addressDetails.physicalAddress = addressDetails.street_number + " " + addressDetails.route;
    }
    else {
      addressDetails.physicalAddress = addressDetails.route;
    }

    this.mapValues.emit(addressDetails);
  }

}
