import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { AuthenticationService } from "../_services";
import { take, map, tap } from "rxjs/operators";
import { NgxPermissionsService } from "ngx-permissions";
import { UsersService } from 'src/app/theme/pages/wealth-spaces/users/_services/users.service';
import { EntitiesService } from 'src/app/_shared/_services/entities.service';

@Injectable()
export class AuthGuard implements CanActivate {
    userEntity;
    constructor(private _router: Router,
        private auth: AuthenticationService,
        private entitiesService: EntitiesService,
        private usersService: UsersService,
        private permissionsService: NgxPermissionsService,
        private router: Router) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | boolean {
        return this.auth.user.pipe(
            take(1),
            map(user => !!user),
            tap(loggedIn => {
                if (!loggedIn) {
                    this._router.navigate(['']);
                }
                else {
                    this.auth.user.subscribe(user => {
                        if (user) {
                            this.entitiesService.getUserPermissionsForEntitySubscription(user.uid).subscribe(userEntityDetails => {
                                this.userEntity = userEntityDetails;
                                this.permissionsService.loadPermissions(this.userEntity['permissions']);
                                if (!user.verified) {
                                    this.router.navigate(['/profile']);
                                }
                            });
                        }
                    });
                }
            }))
    }
}
