import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { CalendarEvent, CalendarView, CalendarEventTitleFormatter } from 'angular-calendar';
import { CustomEventTitleFormatter } from '../_services/custom-event-title.service';
import { Subscription, Observable } from 'rxjs';
import {
  isSameMonth,
  isSameDay
} from 'date-fns';
import { BookingsService } from '../../wealthspaces/ws-bookings/_services/bookings.service';

@Component({
    selector: 'app-bookings-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['../calendars.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
      {
        provide: CalendarEventTitleFormatter,
        useClass: CustomEventTitleFormatter
      }
    ]
})
export class CalendarComponent implements OnInit {

    @Input() bookings$: Observable<Array<CalendarEvent<{ booking: any }>>>;
    @Output() sendBooking = new EventEmitter();

    view: CalendarView = CalendarView.Month;

    viewDate: Date = new Date();

    events$: Observable<Array<CalendarEvent<{ booking: any }>>>;
    activeDayIsOpen: boolean = false;

    bookingsSubscription: Subscription;

    // EDIT BOOKING
    editBooking: boolean = false;
    booking: any;

    // LOCATIONS
    locationId: string;
    locationsSubscription: Subscription;
    locations: any[];
    location: any;
    locationAddress: string;

    constructor(
        private bookingsService: BookingsService
    ) {
    }

    ngOnInit() {
        this.fetchBookings();
    }

    fetchBookings() {
        this.events$ = this.bookings$;
        this.activeDayIsOpen = false;
    }

    dayClicked({
      date,
      events
    }: {
      date: Date;
      events: Array<CalendarEvent<{ booking: any }>>;
    }): void {
      if (isSameMonth(date, this.viewDate)) {
        if (
          (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
          events.length === 0
        ) {
          this.activeDayIsOpen = false;
        } else {
          this.activeDayIsOpen = true;
          this.viewDate = date;
        }
      }
    }

    eventClicked(event: CalendarEvent<{ booking: any }>): void {
      this.booking = event;
      this.editBooking = true;
      this.sendBooking.emit(this.booking);
    }

    hourSegmentModifier(segment) {
      if (segment.date.getHours() < 7) {
        segment.cssClass = 'cal-day-segment-disabled';
      }
    }

}
