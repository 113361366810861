import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { environment } from '../../../../environments/environment';
import { FormsModule } from '@angular/forms';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { TransactionsListComponent } from './transactions-list/transactions-list';
import { CheckInsListComponent } from './checkIns-list/checkIns-list';
import { NotificationsListComponent } from './notifications-list/notifications-list';
import { NavNotificationsListComponent } from './nav-notifications-list/nav-notifications-list';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    useBothWheelAxes: true
};

@NgModule({
    imports: [
      CommonModule,
      RouterModule,
      AngularFireModule.initializeApp(environment.firebase),
      AngularFirestoreModule,
      AngularFireStorageModule,
      FormsModule,
      PerfectScrollbarModule
    ],
    declarations: [
        TransactionsListComponent,
        CheckInsListComponent,
        NotificationsListComponent,
        NavNotificationsListComponent
    ],
    providers: [
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        }
    ],
    exports: [
        TransactionsListComponent,
        CheckInsListComponent,
        NotificationsListComponent,
        NavNotificationsListComponent
    ]
})
export class ScrollableListsModule { }