import { Component, OnInit, Input } from '@angular/core';
import { RatingService } from '../../../_services/ratings.service';
import { config } from '../../../_configs/config';
import { Observable } from 'rxjs/Observable';
import { Subject, Subscription } from 'rxjs';
import { StorageService } from 'src/app/_shared/_services/storage.service';
import { AuthenticationService } from 'src/app/auth/_services';

@Component({
  selector: 'ws-ratings',
  templateUrl: './ws-ratings.component.html',
  styleUrls: ['./ws-ratings.component.scss']
})
export class WSRatingsReviewComponent implements OnInit {


  @Input() ratedUser: any;
  @Input() ratedUserIndex: number = 0;
  @Input() ratedLocation: any;
  @Input() locationId: any;

  user: any;
  userSubscription: Subscription;

  ratingsRef: any;

  ratingsSubscription: Subscription;
  ratings: any;
  avgRating: number;

  ratingId: string;
  ratingType: string;

  currentUser: any;

  constructor(
      private ratingsService: RatingService,
      private storageService: StorageService,
      private auth: AuthenticationService
      )  { }

  ngOnInit() {
    this.currentUser = this.auth.userDetails;
    if (this.ratedUser) {
        this.ratingsRef = `/users/${this.ratedUser.uid}/entities/${config.entityId}/ratings`;
        this.ratingId = this.ratedUser.uid;
        this.ratingType = 'user';
    }
    if (this.ratedLocation) {
        this.ratingsRef = `entities/${config.entityId}/locations/${this.ratedLocation.uid}/ratings`;
        this.ratingId = this.ratedLocation.uid;
        this.ratingType = 'location';
    }


    this.ratingsSubscription = this.ratingsService.getRatingStars(this.ratingsRef).subscribe((stars: any) => {
        this.ratings = stars;
        this.avgRating = this.ratings.reduce((sum, { rating }) => sum + rating, 0) / this.ratings.length;
        if (this.ratedLocation) {
          this.ratingsService.setLocationAvgRating(this.ratedLocation.uid, +this.avgRating.toFixed(1));
        }
    });

    this.userSubscription = this.storageService.userDetails.subscribe(user => {
      if (Object.keys(user).length > 0) {
        this.user = user;
      }
    });
  }

  starHandler(rating) {
    this.ratingsService.addRating(rating, this.ratingsRef, this.ratingType, this.locationId);
  }


}
