import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { environment } from '../../../../environments/environment';

import { ImageCropperModule } from 'ngx-image-cropper';

import { DropZoneDirective } from './drop-zone.directive';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { FileSizePipe } from './file-size.pipe';
import { GalleryUploadComponent } from './gallery-upload/gallery-upload.component';
import { FileListComponent } from './file-list/file-list.component';

@NgModule({
    imports: [
        CommonModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFirestoreModule,
        AngularFireStorageModule,
        ImageCropperModule
    ],
    declarations: [
        FileUploadComponent,
        GalleryUploadComponent,
        DropZoneDirective,
        FileSizePipe,
        FileListComponent
    ],
    providers: [],
    exports: [FileUploadComponent, GalleryUploadComponent, FileListComponent]
})
export class FilesModule { }
