import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WsMeetingRoomsModule } from './ws-meeting-rooms/ws-meeting-rooms.module';
import { WSProductsModule } from './ws-products/ws-products.module';
import { WsEventsModule } from './ws-events/ws-events.module';
import { WsBookingsModule } from './ws-bookings/ws-bookings.module';
import { WsLocationsModule } from './ws-locations/ws.locations.module';
import { WsRatingsModule } from './ws-ratings/ws-ratings.module';
import { WsUsersModule } from './ws-users/ws-users.module';
import { HttpClientModule } from '@angular/common/http';
import { WsMessagesModule } from './ws-messages/ws-messages.module';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    WsMeetingRoomsModule,
    WSProductsModule,
    WsEventsModule,
    WsBookingsModule,
    WsLocationsModule,
    WsRatingsModule,
    WsUsersModule,
    WsMessagesModule
  ],
  exports: [
    WsMeetingRoomsModule,
    WSProductsModule,
    WsEventsModule,
    WsBookingsModule,
    WsLocationsModule,
    WsRatingsModule,
    WsUsersModule,
    WsMessagesModule
  ],
  providers: []
})
export class WealthSpacesCompModule {
}
