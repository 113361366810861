import { Component, OnInit, OnDestroy, Input } from '@angular/core';

@Component({
  selector: 'app-qr-code',
  templateUrl: './qr-code.component.html'
})
export class QrCodeComponent implements OnInit, OnDestroy {

  @Input() extraData: any;

  public qrdata: string = null;

  constructor(
  ) {

  }

  ngOnInit() {
      this.qrdata = this.extraData.uid;
  }

  ngOnDestroy() {
  }

}