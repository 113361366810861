import { Component, OnInit, ViewChild, ElementRef, NgZone, Input, Output, EventEmitter } from '@angular/core';
import { MapsAPILoader, MouseEvent, ZoomControlOptions, ControlPosition, ZoomControlStyle } from '@agm/core';
import { Subject, Subscription, Observable } from 'rxjs';
import { mapStyles } from '../_models/styles.model';
import { StorageService } from 'src/app/_shared/_services/storage.service';

declare var google;

@Component({
  selector: 'app-maps-single-location',
  templateUrl: './maps-single-location.component.html',
  styleUrls: ['../maps.scss']
})
export class MapsSingleLocationComponent implements OnInit {

    @Input() mapSettings: any;

    locationsSubscription: Subscription;
    location: any;

    // google maps zoom level
    zoom: number = 14;

    // initial center position for the map
    lat: number;
    lng: number;

    zoomControlOptions: ZoomControlOptions = {
      position: ControlPosition.RIGHT_TOP,
      style: ZoomControlStyle.SMALL
    };

    mapHeight: string = '100%';

    marker: any;

    mapStyles = mapStyles;

    previous;

    constructor(
        private mapsAPILoader: MapsAPILoader,
        private ngZone: NgZone,
        private storageService: StorageService
    ) { }


    ngOnInit() {

      this.locationsSubscription = this.storageService.singleLocation.subscribe(locationData => {

        this.location = locationData;

        this.lat = this.location.address.latitude;
        this.lng = this.location.address.longitude;

        this.marker = {
          lat: this.location.address.latitude,
          lng: this.location.address.longitude,
          icon: '/assets/images/locations/ws-location.png'
        }
      });
    }

}
