import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NouiFormatter, NouisliderComponent } from 'ng2-nouislider';
import { CurrencyPipe } from '@angular/common';
import { StorageService } from 'src/app/_shared/_services/storage.service';
import { Router } from '@angular/router';

@Component({
  selector: 'ws-meeting-rooms-list',
  templateUrl: './ws-meeting-rooms-list.component.html',
  providers: [CurrencyPipe]
})
export class WSMeetingRoomsListComponent implements OnInit {

  @Input() meetingRooms: any[];
  allMeetingRooms = [];

  meetingRoomPrices = [];

  priceListFilter: any;
  someKeyboardConfig: any = {};
  priceValues: number;

  provincesToFilter = [];

  minPrice: number = 0;
  maxPrice: number = 10000;
  filterMaxPrice: number = 0;
  filterMinPrice: number = 0;

  @ViewChild('priceRange', { static: true }) public priceRange: NouisliderComponent;

  constructor(
    private currencyPipe: CurrencyPipe,
    private storageService: StorageService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.allMeetingRooms = this.meetingRooms;
    this.allMeetingRooms.forEach(room => {
      this.meetingRoomPrices.push(room.hourlyRate);
      if (this.provincesToFilter.indexOf(room.address.region) === -1) {
        this.provincesToFilter.push(room.address.region);
      }
    });
    this.minPrice = this.meetingRoomPrices.reduce((min, p) => p < min ? p : min, this.meetingRoomPrices[0]);
    this.maxPrice = this.meetingRoomPrices.reduce((max, p) => p > max ? p : max, this.meetingRoomPrices[0]);
    if (this.minPrice === this.maxPrice) {
      this.minPrice = 0;
    }
    this.filterMaxPrice = this.maxPrice;
    this.filterMinPrice = this.minPrice;

    this.someKeyboardConfig = {
      connect: true,
      start: [this.minPrice, this.maxPrice],
      step: 10,
      range: {
        min: this.minPrice,
        max: this.maxPrice
      },
      behaviour: 'drag',
      tooltips: [new PriceRangeFormat(this.currencyPipe), new PriceRangeFormat(this.currencyPipe)]
    }
  }

  filterProperties(event, type) {
    if (type === 'price') {
      this.filterMinPrice = event[0];
      this.filterMaxPrice = event[1];
    }

    this.meetingRooms = this.allMeetingRooms.filter(room => {
      return room.hourlyRate >= this.filterMinPrice && room.hourlyRate <= this.filterMaxPrice;
    });

    const province = $('#province-select').children('option:selected').val();
    if (province !== 'all') {
      this.meetingRooms = this.meetingRooms.filter(room => {
        return room.address.region === province;
      });
    }
  }

  resetFilters() {
    this.priceRange.slider.reset();
    $("#province-select").val("all");
    this.meetingRooms = this.allMeetingRooms;
  }

  openMeetingRoomBooking(room) {
    const modalData = {
      modalId: 'bookingRoomModal',
      title: `Book Meeting Room: ${room.name}`,
      type: 'bookMeetingRoom',
      submit: false,
      data: {
        room: room
      }
    }
    this.storageService.openModal(modalData);
  }

  viewMeetingRoom(url) {
    this.router.navigate([`/meeting-room/${url}`]);
  }

}

export class PriceRangeFormat implements NouiFormatter {
  constructor(private currencyPipe: CurrencyPipe) { }

  to(value: number): string {
    const output = this.currencyPipe.transform(value, 'ZAR', 'symbol-narrow', '1.0-0');
    return output;
  }

  from(value: string): number {
    return Number(value.split(' ')[0]);
  }
}
